import React, { useEffect } from "react";

export default function StatusFormTableTd(props) {
  useEffect(() => {}, []);

  return (
    <>
          {props.value.map((a) => (
              (!a.disable) ?(
        <td key={props.Guid()}>{a.value}</td>):''
      ))}
    </>
  );
}
