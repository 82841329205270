import React, { useEffect,useState } from "react";
import {Popover,
    PopoverHeader,
    PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";


export default function PopoverItem(props) {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
  useEffect(() => {
  }, []);



  return (
      <>
    {props.elem.popoverActive ? (
        <>
          {" "}
          <FontAwesomeIcon icon={faQuestionCircle} id={props.elem.name+"p"} />

                  <Popover
            placement="bottom"
            isOpen={popoverOpen}
            target={props.elem.name+"p"}
            toggle={toggle}
          >
            <PopoverHeader>
              {props.translate(
                props.lang,
                props.elem.popoverHeader
              )}
            </PopoverHeader>
            <PopoverBody>
                          {/*{props.translate(
                props.lang,
                props.elem.popoverBody
                          )}*/}
                          <div id={props.elem.name + "hint"} dangerouslySetInnerHTML={{ __html: props.translate(props.lang, props.elem.popoverBody) }} ></div>
            </PopoverBody>
          </Popover>
        </>
      ) : (
        ""
      )}
      </>
  );
}
