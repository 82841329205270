import React, { useEffect } from "react";
import {Label, Input, FormGroup, Button, Card } from "reactstrap";


export default function HomeQAL(props) {
  useEffect(() => {
  }, [props.allQ]);



  return (
      <>
       

          {props.allQ && props.allQ.length > 0 ? (
    <div>          
                  {props.allQ.map((q) => (
                      <Card className="QALCard" key={"card" + q.QuestionId }>
                      <FormGroup key={q.QuestionId}>
                          <Label className="LbBold"> {props.translate(props.lang, "Question")} {q.QuestionSequence}:</Label>{" "}
                              <p>  <span disabled className="textarea" id="question" role="textbox" >{q.Question}</span></p>
                           
                          {q.answers ? (
                            
                              <FormGroup>
                                      <Label className="LbBold">{props.translate(props.lang, "Answer")} {q.QuestionSequence}:</Label>
                                      {q && q.answers && q.answers[0] && q.answers[0].AnswerFormat && q.answers[0].AnswerFormat !== "FREETEXT" &&
                                      q.answers[0].AnswerFormat !== "DATE" &&
                                      
                                      q.answers ? (
                                         <div>
                                          <Input
                                            type="select"
                                            valid={q.AnswerId && q.AnswerId > 0}                                           
                                            onChange={(e) => {                                                 
                                                q.AnswerId = e.target.value;
                                              
                                                let tellCaller = q.answers.find(s => s.AnswerId.toString() === e.target.value.toString());
                                                if (tellCaller) {
                                                    q.TellCaller = tellCaller.TellCaller;     
                                                    document.getElementById("tellcaller" + q.QuestionId).innerHTML=q.TellCaller;
                                                }
                                              }} >
                                                  {q.answers.map((a) => (
                                                      <option key={a.AnswerId} id={"answerid"+a.AnswerId} value={a.AnswerId} >
                                                      {a.Answer}
                                                  </option>
                                              ))}
                                              </Input>
                                          
                                              <span className="qa-label" id={"tellcaller" + q.QuestionId}>{q.TellCaller}</span>
                                            </div> 
     

                                  ) : (
                                      ""
                                  )}

                                      {(q && q.answers && q.answers[0] && q.answers[0].AnswerFormat && q.answers[0].AnswerFormat === "FREETEXT") && q.answers ? (
                                      <div>
                                    
                                              <span className="textareaEdit" id="question" role="textbox" contentEditable autoComplete="on"
                                                  id={q.answers[0].AnswerId}
                                                  name={q.answers[0].AnswerId}
                                                  key={q.answers[0].AnswerId}
                                                  onInput={(e) => {
                                                   
                                                      if (e.currentTarget.textContent && e.currentTarget.textContent.length>0) {
                                                          e.currentTarget.classList.add("is-valid");
                                                          e.currentTarget.classList.add("form-control");
                                                      } else {
                                                          e.currentTarget.classList.remove("is-valid");
                                                          e.currentTarget.classList.remove("form-control");
                                                      }
                                                      q.answers[0].Answer = e.currentTarget.textContent;                                          
                                                  }} >
                                              </span>
                                      <span className="qa-label" id={"tellcaller" + q.QuestionId}>{q.TellCaller}</span>
                                          </div>

                                  ) : (
                                      ""
                                  )}
                                   
                                      {q && q.answers && q.answers[0] && q.answers[0].AnswerFormat && q.answers[0].AnswerFormat === "DATE" && q.answers ? (
                                         
                                          <div>
                                              <Input className="LbBold" 
                                          type="date"
                                          name="date"
                                                  id={q.answers[0].AnswerId}
                                        
                                          placeholder=""
                                                  onChange={(e) => {
                                                      let val = e.target.value.toString();
                                                      q.answers[0].Answer =val;
                                        
                                                      if (val && val.toLowerCase() != "date") {
                                                          e.currentTarget.classList.add("is-valid");
                                                          e.currentTarget.classList.add("form-control");
                                                      } else {
                                                          e.currentTarget.classList.remove("is-valid");
                                                          e.currentTarget.classList.remove("form-control");
                                                      }
                                                     
                                                 
                                          }}
                                      />
                                          <span className="qa-label" id={"tellcaller" + q.QuestionId}>{q.TellCaller}</span>
                                          </div>
                                  ) : (
                                      ""
                                  )}
                              </FormGroup>



      







                          ) : (
                              ""
                          )}


                      </FormGroup>
</Card>

                  ))}

</div>


          ) : ("")}

    
    
   
      </>
  );
}
