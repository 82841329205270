import React, { useEffect, useRef } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import PopoverItem from "../common/PRpopover";

export default function HomeAttach(props) {
    const fileInput = useRef(null);

    useEffect(() => {
    }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = () => {
    fileInput.current.click();
    };


    return (
        <div>
        
            <FormGroup  tag="fieldset">
        <Label className={props.fstate.image.fontWeight ? "label-font" : ""}>
          {props.translate(props.lang, props.fstate.image.label)}{" "}
          {!props.fstate.image.required ? (
            <span className="colorred">
              {props.translate(props.lang, "optionalSign")}
            </span>
          ) : (
            <></>
          )}
        </Label>
        <PopoverItem
          elem={props.fstate.image}
          translate={props.translate}
          lang={props.lang}
              />
              <span className="attach_required">
              {props.fstate.image.required == true && props.fstate.imageList &&
                  props.fstate.imageList.length == 0
                  ?
                  props.translate(props.lang, "AttachmentIsRequired")  +
                  (props.errmsg ? props.translate(props.lang, props.errmsg) : "")
                      : ""}
              </span>

      </FormGroup>

      <FormGroup>
        <div className="file-input-wrapper">
          <div
            className="d-flex justify-content-around"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
                      <div className="d-flex dragdrop containerFlex">
                          <p className="itemFlex"> {props.translate(props.lang, "DragFiles")} </p>
                          {props.limitFilesAttach ? <p className="itemFlex">Accept only: {props.limitFilesAttach} </p> : ''}
                          {props.maxFileSize>0 ? <p className="itemFlex">Max size file limit: {props.maxFileSize} </p>:''}
                          <div className="btnAddImage itemFlex">
                <Button
                  color="info"
                  id="AddImage"
                                  onClick={(e) => handleClick(e)}
                >
                                  {props.translate(props.lang, "AddAttchmentLabel")}
                </Button>
              </div>
            </div>
          </div>
          <Input
            ref={fileInput}
            type="file"
            name="image"
            id="imageInput"
            value=""
            onChange={(e) => {
              props.attach(e);
                      }}
                      accept={props.limitFilesAttach ? props.limitFilesAttach : '*.*'}
                      
          />
        </div>
      </FormGroup>
    

          {props.fstate.imageList
              ? props.fstate.imageList.map((a) => (
                  <FormGroup key={a.guid} id={a.guid}>
                      <InputGroup key={a.guid+"ig"}>
                          <Input key={a.guid + "i"} placeholder={a.filename}
                              onChange={(e) => {
                                  console.log("event on change", e.target.value);
                                  props.onChangeImageName(e, a.guid);
                                
                              }}
                          />
                          <InputGroupAddon key={a.guid + "iga"} addonType="prepend">
                              <InputGroupText key={a.guid + "igt"}
                    onClick={(e) => {
                      props.deleteAttach(e, a.guid);
                      
                    }}
                  >
                            {props.translate(props.lang, "Delete")}
                  </InputGroupText>
                </InputGroupAddon>
                          <InputGroupAddon key={a.guid + "igad"} addonType="append">
                              <InputGroupText key={a.guid + "igtk"}
                    onClick={(e) => {
                      props.previewAttach(e, a.guid);
                    }}
                  >
                            {a.preview ? props.translate(props.lang, "Preview") + "..." : props.translate(props.lang, "Close") + "..."}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          ))
        : ""}

      {props.prevFile ? (
        <FormGroup>
                    <img key={props.Guid()} id="preview" alt="" src={props.prevFile}></img>
        </FormGroup>
      ) : (
        ""
      )}
    </div>
  );
}
