
import React, { createRef, forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react";

import { Form, Button, Label, Col, Card } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import PRInput from "../common/PRInput";
import PRCheckbox from "../common/PRCheckbox";
import HomeSelect from "./home-auto-select";
import HomeQA from "./home-qa";
import HomeQAL from "./home-qa-linear";
import HomeAttach from "./home-attach";
import HomeHyperlink from "./home-hyperlink";
import { WebMapView } from "./home-map";


function HomeForm(props) {
    const recaptchaRef = useRef(null);
    const mapRef = useRef();
    const [step, setStep] = useState(1);

    

    useEffect(() => {
      
        pageTwoValidationClick();
        
     }, [props.markUpSelected]);



    const submitForm = (e) => {
        e.preventDefault();
        props.clearAlerts();

        if (props.usecaptcha) {
            recaptchaRef.current.execute();
        } else {
            props.submitForm();
        }
    };

    const onChange = (value) => {
        props.submitForm();

    };

  

    const openStep = (e, step, prevStep, clear=false) => {
        e.stopPropagation();
        setStep(step);
        props.clearAlerts();   
        if (!(prevStep == 1 && step== 2)) {
            if (!clear) {
                if (prevStep == 2 && step == 3)
                props.sreenshoot(props);
            } 
        }

        if (step == 2) {
            try { pageTwoValidationClick(); } catch { }
        }
  };


  const showPRdescription = () => {
    let a = props.problemTypes.find(
      (e) => e.problemSid === props.fstate.problemtype.value
    );
    if (a) {
      return a.description;
    } else return "";
  };

    const saveScreenshot = (image) => {
    //Block validation on required roblem type begin
        if (
            (props.useMapGraphics === true &&
                props.drawingRequiredForAllTypes === true) ||
            (props.drawingRequiredForAllTypes !== true &&
                document.getElementById("problemSelectLabel") &&
                document
                    .getElementById("problemSelectLabel")
                    .getAttribute("drawingRequiredForTemplateKeywordFound") &&
                document
                    .getElementById("problemSelectLabel")
                    .getAttribute("drawingRequiredForTemplateKeywordFound")
                    .toString()
                    .toLowerCase() === "true")
        ) {
            props.loadDiv("show");
            props.attachMapImage(image);
        } else {
            props.loadDiv("show");
            props.attachMapImage(null);
        }

  };

 

    const pageOneValidationClick = () => {

        try {
             if (
                 (props.fstate.problemtype.value && props.fstate.additionalDetail.required != true) ||
                 (props.fstate.problemtype.value && props.fstate.additionalDetail.required === true && props.fstate.additionalDetail.value && props.fstate.additionalDetail.value.length > 0)
             ) {

                 if (props.fstate.image.required != true || (props.fstate.image.required == true && props.fstate.imageList.length > 0)) {


                     if (!props.branchingModel) return true;
                     else {
                         if (
                             props.branchingModel && !(props.curA && props.curQ)
                         )
                             return true;
                         else return false;
                     }
                 } else return false;

             } else {
                 return false;
             }
         } catch {return false; }
    }

    const colorBtnPolygon = (color) => {
        try {
            if (document.querySelector('calcite-action[text="Draw a polygon"]').shadowRoot.querySelector('button[aria-label="Draw a polygon"]'))
                document.querySelector('calcite-action[text="Draw a polygon"]').shadowRoot.querySelector('button[aria-label="Draw a polygon"]').style.color = color;

        } catch { }
    }


    const pageTwoValidationClick = () => {
        if (props.fstate.problemLocation.value &&
            (props.fstate.locationDetails.required != true) ||
            (props.fstate.locationDetails.value && props.fstate.locationDetails.required === true &&
                props.fstate.locationDetails.value.length > 0)
        ) {
            let markUpGraphics = false;
            /*   if (props && props.markUpSelected && props.markUpSelected.graphics && props.markUpSelected.graphics.items && props.markUpSelected.graphics.items.length > 0)*/
            if (props && props.markUpSelected && props.markUpSelected.length > 0)
                markUpGraphics = true;

            colorBtnPolygon("black");

            if (props.useMapGraphics) {
                //if (props.drawingRequiredForAllTypes == false && props.drawingRequiredForTemplateKeyword == "")
                //    return true;
                //else {
                    if (props.drawingRequiredForAllTypes === false) {
                        if (props.drawingRequiredForTemplateKeyword == "") {
                          /*  if (markUpGraphics)*/
                                return true;
                           /* else return false;*/
                        } else { //props.drawingRequiredForTemplateKeyword !== ""

                            let pbTypeReq = "false";
                            if (document.getElementById("problemSelectLabel") &&
                                document
                                    .getElementById("problemSelectLabel")
                                    .getAttribute("drawingRequiredForTemplateKeywordFound")) {
                                pbTypeReq = document
                                    .getElementById("problemSelectLabel")
                                    .getAttribute("drawingRequiredForTemplateKeywordFound")
                                    .toString()
                                    .toLowerCase();
                            }
                            if (pbTypeReq === "true") {
                                if (markUpGraphics) {
                                    console.log(1);
                                    colorBtnPolygon("green"); return true;
                                } else {
                                    console.log(2);
                                    colorBtnPolygon("red"); return false;
                                }
                            }
                            else {
                                //colorBtnPolygon("green");
                                return true;
                            }

                        }
                    } else { //props.drawingRequiredForAllTypes === true
                        if (markUpGraphics)
                        { colorBtnPolygon("green"); return true; }
                        else {
                          
                                colorBtnPolygon("red");
                            return false;}
                        }
                 //   }
               // }
            }
            else {
                // => !props.useMapGraphics
                return true;

            }
        } else {
            return false;
        }

    }

 


  const getAddress = (point, pointWkid, mapWkid, address, x, y) => {
    document.getElementById("popup_address").setAttribute("address_x", "-1");
    document.getElementById("popup_address").setAttribute("address_y", "-1");
    document
      .getElementById("popup_address")
      .setAttribute("address_address", "");
    const centerYRequest2 = {
      inSR: pointWkid,
      outSR: 102100,
      geometries: JSON.stringify({
        geometryType: "esriGeometryPoint",
        geometries: [point],
      }),
      geometryUrl: props.geomUrl,
      geometryToken: props.geomToken,
      geomTokenRequired: props.geomTokenRequired,
      userSettingsId: props.userSetId,
      cwtoken: props.token,
    };

    const requestOptionsCenter2 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(centerYRequest2),
    };

    try {
      window
        .fetch("GetExtentCenter", requestOptionsCenter2)
        .then((resp) => {
          props.loadDiv("hide");
          return resp.json();
        })
        .then((data) => {
          try {
            if (data.status !== 0) {
              props.setAlerts(data.message, "danger");
            } else {
              let value = data.value;
              props.fstate.problemLocation.value = address;
              props.fstate.problemLocation.valueDesc = address;
              props.fstate.coordinatesx.value = value["x"];
              props.fstate.coordinatesy.value = value["y"];

              props.updateLocationAddress(
                address,
                value["x"],
                value["y"]
              );
              if (document.getElementById("popup_address")) {
                document
                  .getElementById("popup_address")
                  .setAttribute("address_x", value["x"].toString());
                document
                  .getElementById("popup_address")
                  .setAttribute("address_y", value["y"].toString());
                document
                  .getElementById("popup_address")
                  .setAttribute("address_address", address);
               
                  document.getElementById("popup_address").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' })); 
              }
            }
          } catch (error) {
            props.loadDiv("hide");
            console.log("Error: ", error);
            setAlert(error, "danger");
          }
        });
    } catch (error) {
      props.loadDiv("hide");
      console.log("Error: ", error);
      setAlert(error, "danger");
    }
  };

     const getLocation = (point, pointjson, mapWkid, pointWkid) => {
      
    document.getElementById("popup").setAttribute("point", "{}");
    document.getElementById("popup").setAttribute("data", "{}");
    document
      .getElementById("popup")
             .setAttribute("point", JSON.stringify(point));

    let addressFromXYRequest = {
      inSR: mapWkid,
      outSR: pointWkid,
      geometries: JSON.stringify(pointjson),
      geometryUrl: props.geomUrl,
      geometryt: props.geomToken,
      geocoderUrl: props.gcoderUrl,
      geocodert: props.gcoderToken,
      addressField: props.addressField,
      geomTokenRequired: props.geomTokenRequired,
      gcoderTokenRequired: props.gcoderTokenRequired,
      longitude: point.longitude,
      latitude: point.latitude,
      userSettingsId: props.userSetId,
      cwtoken: props.token,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addressFromXYRequest),
    };
    try {
      window
        .fetch("GetAddressFromXY", requestOptions)
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
            try {
                props.loadDiv("hide");
                if (data.value === "No address found.") {
                    let address = JSON.stringify(data.location);
                    props.fstate.problemLocation.value = address;
                    props.fstate.problemLocation.valueDesc = address;
                    props.fstate.coordinatesx.value = data.location.x;
                    props.fstate.coordinatesy.value = data.location.y;

                    props.updateLocationAddress(
                        address,
                        data.location.x,
                        data.location.y
                    );
                    //document.getElementById("problemLocation").value = address;
                    //document.getElementById("problemLocation").focus();
                   // document.getElementById("problemLocation").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' }));
                }
                else {
                    let address = data.value;
                    if (data.value === "") {// data.value = JSON.stringify(data.location);
                       address = JSON.stringify(data.location);
                    }
                    props.fstate.problemLocation.value = address;
                    props.fstate.problemLocation.valueDesc = address;
                    props.fstate.coordinatesx.value = data.location.x;
                    props.fstate.coordinatesy.value = data.location.y;
                    //document.getElementById("problemLocation").value = address;
                    //document.getElementById("problemLocation").focus();
                   // document.getElementById("problemLocation").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' }));

                    
                    props.updateLocationAddress(
                        address,
                        data.location.x,
                        data.location.y
              );
            }

            //if (document.getElementById("popup")) {
            //  document
            //    .getElementById("popup")
            //    .setAttribute("data", JSON.stringify(data));
              
            //    document.getElementById("popup").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' }));
            //}
          } catch (error) {
            props.loadDiv("hide");
            console.log("Error: ", error);
           // props.setAlerts(error, "danger");
          }
        });
    } catch (error) {
      props.loadDiv("hide");
      console.log("Error: ", error);
      //props.setAlerts(error, "danger");
    }
  };

   

  return (
      <div className="maxElement">
       
      <Form 
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
          >
             
              <Col>
          {props.fstate.reportTitle.disable === false ? (
            <div className="d-flex justify-content-center">
              <Label className="title" for="reportTitle">
                <div className="d-flex justify-content-center">
                  <span
                    className={
                      props.fstate.reportTitle.fontWeight ? "label-font" : ""
                    }
                  >
                    {props.translate(
                      props.lang,
                      props.fstate.reportTitle.label
                    )}
                  </span>
                </div>
              </Label>
            </div>
          ) : (
            ""
          )}

          <div className={step === 1 ? "showElement" : "hideElement"}>
            {props.fstate.reportInfoOnTop.disable === false ? (
              <div className="d-flex ">
                <Label className="infoTop" for="reportInfoOnTop">
                  <div className="d-flex ">
                    <span
                      className={
                        props.fstate.reportInfoOnTop.fontWeight
                          ? "label-font"
                          : ""
                      }
                    >
                      {props.translate(
                        props.lang,
                        props.fstate.reportInfoOnTop.label
                      )}
                    </span>
                  </div>
                </Label>
              </div>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-end">
              <HomeHyperlink
                isHome={props.isHome}
                translate={props.translate}
                lang={props.lang}
                changeLang={props.changeLang}
                useTranslation={props.useTranslation}
                useFontOptions={props.useFontOptions}
                changePage={props.changePage}
                fontSize={props.fontSize}
                changeFont={props.changeFont}
                userSetId={props.userSetId}
                setAlerts={props.setAlerts}
              />
            </div>

                      
                      <Card className="cardStyle">
 {/*  reRefresh={props.reRefresh}*/}
                              <HomeSelect
                              problemTypes={props.problemTypes}
                              fstate={props.fstate}
                              onchange={props.onchange}
                              getQA={props.getQA}
                              problemTypesCommon={props.problemTypesCommon}
                              translate={props.translate}
                              lang={props.lang}
                              openProblemsList={props.openProblemsList}
                              userSetId={props.userSetId}
                              setAlerts={props.setAlerts}
                              drawingRequiredForTemplateKeyword={
                                  props.drawingRequiredForTemplateKeyword
                              }
                              drawingRequiredForAllTypes={props.drawingRequiredForAllTypes}
                           
                              showMap={props.showMap}
                              hideMapByTemplateKeyword={props.hideMapByTemplateKeyword }
                              />
                          {props.branchingModel && props.fstate.problemtype ?
                              <HomeQA
                                  allQ={props.allQ}
                                  fstate={props.fstate}
                                  curQ={props.curQ}
                                  curAall={props.curAall}
                                  curA={props.curA}
                                  selectedQA={props.selectedQA}
                                  translate={props.translate}
                                  lang={props.lang}
                                  onSelectAnswer={props.onSelectAnswer}
                                  nextQA={props.nextQA}
                                  Guid={props.Guid}
                                  Answer={props.Answer}
                                  userSetId={props.userSetId}
                                  setAlerts={props.setAlerts}
                              /> :
                              <HomeQAL
                                  allQ={props.allQ}
                                  fstate={props.fstate}
                                  curQ={props.curQ}
                                  curAall={props.curAall}
                                  curA={props.curA}
                                  selectedQA={props.selectedQA}
                                  translate={props.translate}
                                  lang={props.lang}
                                  onSelectAnswerL={props.onSelectAnswerL}
                                  nextQA={props.nextQA}
                                  Guid={props.Guid}
                                  Answer={props.Answer}
                                  userSetId={props.userSetId}
                                  setAlerts={props.setAlerts}
                                  problemtype={props.fstate.problemtype}
                              />}
                           
                      </Card>


                      <Card className="cardStyle">
                          <div>
                              <PRInput
                                  elem={props.fstate.additionalDetail}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                                  translate={props.translate}
                                  placeholder={props.fstate.additionalDetail.placeholder}
                                 
                                  lang={props.lang}
                              />

                              <HomeAttach
                                  fstate={props.fstate}
                                  translate={props.translate}
                                  lang={props.lang}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                                  onChangeImageOption={props.onChangeImageOption}
                                  attach={props.attach}
                                  deleteAttach={props.deleteAttach}
                                  previewAttach={props.previewAttach}
                                  imageOption={props.imageOption}
                                  prevFile={props.prevFile}
                                  limitFilesAttach={props.limitFilesAttach}
                                  maxFileSize={props.maxFileSize}
                                  userSetId={props.userSetId}
                                  setAlerts={props.setAlerts}
                                  imageList={props.fstate.imageList}
                                  Guid={props.Guid}
                                  onChangeImageName={props.onChangeImageName}
                               
                              />
                          </div>
                      </Card>
                          
          </div>


          <div className={step === 1 ? "showElement" : "hideElement"}>
            <div className="d-flex justify-content-center">
              <Button
                              className={pageOneValidationClick() ? "" : "noAllowed"}
                              color={pageOneValidationClick() ? "info" : "secondary"}
                onClick={(e) => {
                    e.stopPropagation();
                
                    if (pageOneValidationClick()) {
                      
                        if (
                            (
                                document.getElementById("core_state")
                                && document
                                .getElementById("core_state")
                                    .getAttribute("showmapkeyword") == "true")
                            ? true : false)
                            
                        {
                        
                            openStep(e, 2, step);
                            }
                        else { 
                          openStep(e, 3, step);
                        }
                    }
            
                }}
              >
                {props.translate(props.lang, "Next")}
              </Button>
            </div>
          </div>

          <div className={step === 2 ? "showElement" : "hideElement"}>
            <Card className="cardStyle">
              <div className="selectedData">
                <div className="row">
                  <div className="col">
                    {props.translate(props.lang, "TypeOfProblemLabel")}
                  </div>
                  <div className="col font-weight-bold">
                    {showPRdescription()}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {props.translate(props.lang, "LocationOfProblemLabel")}
                  </div>
                  <div className="col font-weight-bold">
                    {" "}
                    {props.fstate.problemLocation.value}
                  </div>
                </div>
              </div>
            </Card>


                      <Card className="cardStyle" id="mapPlaceHolder">
                          {props.isShow ? (
                              <WebMapView 
                                  sreenshoot={props.sreenshoot}
                                  bodyState={props.bodyState}
                                  /*  refresh={props.refresh}*/
                                  isShow={props.isShow}
                                  getLocation={getLocation}
                                  fstate={props.fstate}
                                  translate={props.translate}
                                  lang={props.lang}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                                  onSearchChange={props.onSearchChange}
                                  Guid={props.Guid}
                                  setAndValidate={props.setAndValidate}
                                  updateLocationAddress={props.updateLocationAddress}
                                  saveScreenshot={saveScreenshot}
                                  gisAddreses={props.gisAddreses}
                                  maprefresh={props.maprefresh}
                                  extent={props.extent}
                                  sp={props.sp}
                                  geomUrl={props.geomUrl}
                                  geomToken={props.geomToken}
                                  gcoderUrl={props.gcoderUrl}
                                  gcoderToken={props.gcoderToken}
                                  addressField={props.addressField}
                                  geomTokenRequired={props.geomTokenRequired}
                                  gcoderTokenRequired={props.gcoderTokenRequired}
                                  loadDiv={props.loadDiv}
                                  getAddreses={props.getAddreses}
                                  clearAlerts={props.clearAlerts}
                                  FeatureServiceUrl={props.FeatureServiceUrl}
                                  userSetId={props.userSetId}
                                  token={props.token}
                                  setAlerts={props.setAlerts}
                                  useMapGraphics={props.useMapGraphics}
                                  availableCreateTools={props.availableCreateTools}
                                  useMapMeasurement={props.useMapMeasurement}
                                  drawingRequiredForAllTypes={props.drawingRequiredForAllTypes}
                                  drawingRequiredForTemplateKeyword={
                                      props.drawingRequiredForTemplateKeyword
                                  }
                                  drawingRequiredForTemplateKeywordFound={
                                      props.drawingRequiredForTemplateKeywordFound
                                  }
                                  markUpSelected={props.markUpSelected}
                                  setupMarkUpSelected={props.setupMarkUpSelected}
                                  attachMapImage={props.attachMapImage}
                                  getAddress={getAddress}
                                  listOfAddressCandidates={props.listOfAddressCandidates}
                                  problemTypes={props.problemTypes}
                                  updateMapLoad={props.updateMapLoad}
                                  webMapId={props.webMapId}
                                  webMapUrl={props.webMapUrl}
                                  showMap={props.showMap}
                                  hideMapByTemplateKeyword={props.hideMapByTemplateKeyword}
                                  listimages={props.fstate.imageList}
                                  refresh={props.refresh}
                                  pageTwoValidationClick={pageTwoValidationClick}
                                  geoCodeZoomLevel={props.geoCodeZoomLevel}
                                /*  mapView={mapView}*/
                              />
                          ) : (
                              ""
                          )}

                          <br />

                          <PRInput
                              elem={props.fstate.locationDetails}
                              validate={(e) => {
                                  props.validate(e);
                              }}
                              translate={props.translate}
                              placeholder={props.fstate.locationDetails.placeholder}
                              lang={props.lang}
                          />
                      </Card>

            <div className="d-flex justify-content-center">
              <Button
                color="info"
                onClick={(e) => {
                  openStep(e, 1, step);
                }}
              >
                {props.translate(props.lang, "Back")}
              </Button>
              <Button 
                              className={pageTwoValidationClick() ? "" : "noAllowed"}
                              color={pageTwoValidationClick() ? "info" : "secondary"}
                              onClick={(e) => {
                   
                                  if (pageTwoValidationClick()) {
                                      openStep(e, 3, step);
                                  }
                }}
              >
                {props.translate(props.lang, "Next")}
              </Button>
            </div>
          </div>

          <div className={step === 3 ? "showElement" : "hideElement"}>
            <Card className="cardStyle">
              <div className="selectedData">
                <div className="row  font-weight-bold">
                  <div className="col">
                    {props.translate(props.lang, "RequestSummaryLabel")}
                  </div>
                </div>
                <div className="row">
                  <div className="col col-sm-4">
                    {props.translate(props.lang, "TypeOfProblemLabel")}
                  </div>
                  <div className="col col-sm-6 font-weight-bold">
                    {showPRdescription()}
                  </div>

                  <div>
                    <Button
                      color="link"
                      onClick={(e) => {
                        openStep(e, 1, step);
                      }}
                    >
                      {props.translate(props.lang, "Edit")}
                    </Button>
                  </div>
                              </div>
                              {(props.showMap && (
                                  (document.getElementById("core_state")
                                      && document
                                          .getElementById("core_state")
                                          .getAttribute("showmapkeyword")=="true"
                                  ) ? true : false
                              )) ?
                                  <div>
                <div className="row">
                  <div className="col col-sm-4">
                    {props.translate(props.lang, "LocationOfProblemLabel")}
                  </div>
                  <div className="col col-sm-6 font-weight-bold">
                    {" "}
                    {props.fstate.problemLocation.value}
                  </div>
                  <div>
                    <Button
                      color="link"
                                          onClick={(e) => {
                                            
                        openStep(e, 2, step);
                      }}
                    >
                      {props.translate(props.lang, "Edit")}
                    </Button>
                  </div>
                </div>
                <div className="row" id={props.Guid()}>
                  <div className="col col-sm-4"></div>
                  <div
                    className="col col-sm-6 font-weight-bold"
                    id="div_sreenshot_thumbnail"
                  >
                    <img

                      g={props.Guid()}
                      className="screenshot_thumbnail"
                      id="screenshot_thumbnail_id"
                      src={props.fstate.screenshot_img}
                      alt="name"
                    ></img>
                  </div>{" "}
                </div>
                                  </div>
                                  : ""}
                {props.fstate.additionalDetail.value ? (
                  <div className="row">
                    <div className="col col-sm-4">
                      {props.translate(props.lang, "AddDetailLabel")}
                    </div>
                    <div className="col col-sm-6 font-weight-bold">
                      {props.translate(
                        props.lang,
                        props.fstate.additionalDetail.value
                      )}
                    </div>
                    <div>
                      <Button
                        color="link"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                          openStep(e, 1, step);
                        }}
                      >
                        {props.translate(props.lang, "Edit")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {props.fstate.imageList && props.fstate.imageList.length > 0 ? (
                  <div className="row" id={props.Guid()}>
                    <div className="col col-sm-4">
                      {props.translate(props.lang, "AttachmentsLabel")}
                    </div>
                    <div
                      className="col col-sm-6 font-weight-bold"
                      id={props.Guid()+"next"}
                    >
                      {props.fstate.imageList.map((a) => (
                        <img
                          id={a.guid}
                          key={a.guid}
                          title={a.filename}
                          className="thumbnail"
                          alt=""
                          src={a.data}
                        ></img>
                      ))}
                    </div>
                    <div>
                      <Button
                        id={props.Guid()}
                        color="link"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                          openStep(e, 1, step);
                        }}
                      >
                        {props.translate(props.lang, "Edit")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                              )}

                              {props.fstate.locationDetails.value && (props.showMap && (
                                  (document.getElementById("core_state")
                                      && document
                                          .getElementById("core_state")
                                          .getAttribute("showmapkeyword")=="true"
                                  ) ? true : false
                              )) ? (
                                  <div className="row">
                                      <div className="col col-sm-4">
                                          {props.translate(props.lang, "LocationDetailLabel")}
                                      </div>
                                      <div className="col col-sm-6 font-weight-bold">
                                          {props.translate(
                                              props.lang,
                                              props.fstate.locationDetails.value
                                          )}
                                      </div>
                                      <div>
                                          <Button
                                              color="link"
                                              onClick={(e) => {
                                                  e.stopPropagation();  
                                               
                                                  openStep(e, 2, step);
                                              }}
                                          >
                                              {props.translate(props.lang, "Edit")}
                                          </Button>
                                      </div>
                                  </div>
                              ) : (
                                  ""
                              )}

              </div>
            </Card>
            <Card className="cardStyle">
              <div className="flex-element">
                <PRInput
                                  elem={props.fstate.firstName}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                                  translate={props.translate}
                                  lang={props.lang}
                                  errmsg={props.fstate.firstName.errorlabel}
                                  placeholder={props.fstate.firstName.placeholder}
                                 
                />
              </div>{" "}
              <div className="flex-element">
                <PRInput
                  elem={props.fstate.lastName}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                  translate={props.translate}
                  lang={props.lang}
                  errmsg={props.fstate.lastName.errorlabel}
                  placeholder={props.fstate.lastName.placeholder}
                />
              </div>
              <PRInput
                elem={props.fstate.address}
                              validate={props.validate}
                              validateSpan={props.validateSpan}
                translate={props.translate}
                lang={props.lang}
                errmsg={props.fstate.address.errorlabel}
                placeholder={props.fstate.address.placeholder}
              />
             
              <div className="flex-element">
                <PRInput
                  elem={props.fstate.addressaptnum}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                  translate={props.translate}
                  lang={props.lang}
                  errmsg={props.fstate.addressaptnum.errorlabel}
                  placeholder={props.fstate.addressaptnum.placeholder}
                />
              </div>
              <div className="flex-element">
                <PRInput
                  elem={props.fstate.addresscity}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                  translate={props.translate}
                  lang={props.lang}
                  errmsg={props.fstate.addresscity.errorlabel}
                  placeholder={props.fstate.addresscity.placeholder}
                />
              </div>
              <div className="flex-element">
                <PRInput
                  elem={props.fstate.addresszipcode}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                  translate={props.translate}
                  lang={props.lang}
                  errmsg={props.fstate.addresszipcode.errorlabel}
                  placeholder={props.fstate.addresszipcode.placeholder}
                />
              </div>
              <div className="flex-element">
                <PRInput
                  elem={props.fstate.phone}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                  translate={props.translate}
                  lang={props.lang}
                  errmsg={props.fstate.phone.errorlabel}
                  placeholder={props.fstate.phone.placeholder}
                />
              </div>
              <div className="flex-element">
                <PRInput
                  elem={props.fstate.email}
                                  validate={props.validate}
                                  validateSpan={props.validateSpan}
                  translate={props.translate}
                  lang={props.lang}
                  errmsg={props.fstate.email.errorlabel}
                  placeholder={props.fstate.email.placeholder}
                />
              </div>
              <div className="flex">
                <div className="flex-element">
                  <PRInput
                    elem={props.fstate.coordinatesx}
                                      validate={props.validate}
                                      validateSpan={props.validateSpan}
                    translate={props.translate}
                    lang={props.lang}
                  />
                </div>
                <div className="flex-element">
                  <PRInput
                    elem={props.fstate.coordinatesy}
                                      validate={props.validate}
                                      validateSpan={props.validateSpan}
                    translate={props.translate}
                    lang={props.lang}
                  />
                </div>
              </div>
              <PRCheckbox
                elem={props.fstate.isFollowUpCall}
                              validate={props.validate}
                              validateSpan={props.validateSpan}
                translate={props.translate}
                lang={props.lang}
              />
              <PRCheckbox
                elem={props.fstate.emailRequest}
                              validate={props.validate}
                              validateSpan={props.validateSpan}
                translate={props.translate}
                lang={props.lang}
              />
            </Card>
            <div className="d-flex justify-content-center">
              <Button id="btnSubmit"
                color="info"
                disabled={props.btnSubmitDisabled}
                onClick={(e) => {
                    let result = props.validationForm();
                
                    if (document.getElementById("btnSubmit").classList.contains("noAllowed")) return;
                    if (result) {
                        submitForm(e);
                        openStep(e, 1, step);
                    }
                }}
              >
                {props.translate(props.lang, "Submit")}
              </Button>
              <Button
                color="outline-info"
                className="clearBtn"
                id="btnClear"
                              onClick={(e) => {
                                
                  props.clearForm(e);
                                  openStep(e, 1, step, true);
                                  props.fstate.imageList = [];
                }}
              >
                {props.translate(props.lang, "Clear")}
              </Button>
            </div>
            {props.usecaptcha ? (
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={props.recaptchaSiteKey}
                onChange={onChange}
              />
            ) : (
              ""
            )}

            {props.fstate.reportInfoOnBottom.disable === false ? (
              <div className="d-flex ">
                <Label className="infoBottom" for="reportInfoOnBottom">
                  <div className="d-flex ">
                    <span
                      className={
                        props.fstate.reportInfoOnBottom.fontWeight
                          ? "label-font"
                          : ""
                      }
                    >
                      {props.translate(
                        props.lang,
                        props.fstate.reportInfoOnBottom.label
                      )}
                    </span>
                  </div>
                </Label>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Form>
    </div>
  );
}


export default HomeForm;