export const sampleState = {
  reportDescription: {
    name: "reportDescription",
    Label: "reportDescriptionLabel",
    required: true,
    disable: false,
    fontWeight: false,
    usepattern: false,
    errorLabel: "",
    value: "",
    status: -1,
  },
  problemtype: {
    name: "problemtype",
    Label: "problemtypeLabel",
    required: true,
    disable: false,
    fontWeight: false,
    usepattern: false,
    errorLabel: "Problem Type",
    value: -1,
    status: -1,
  },
  image: {
    name: "image",
    Label: "imageLabel",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Image",
    value: "",
    status: -1,
  },
  firstName: {
    name: "firstName",
    Label: "firstNameLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "First Name",
    value: "",
    status: -1,
  },
  lastName: {
    name: "lastName",
    Label: "lastNameLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Last Name",
    value: "",
    status: -1,
  },
  address: {
    name: "address",
    Label: "addressLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Address",
    value: "",
    status: -1,
  },
  phone: {
    name: "phone",
    Label: "phoneLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: true,
    errorLabel: "Phone Number",
    value: "",
    status: -1,
  },
  email: {
    name: "email",
    Label: "emailLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: true,
    errorLabel: "Email",
    value: "",
    status: -1,
  },
  problemLocation: {
    name: "problemLocation",
    Label: "problemLocationLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Location",
    value: "",
    status: -1,
  },
  locationDetails: {
    name: "locationDetails",
    Label: "locationDetailsLabel",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Details",
    value: "",
    status: -1,
  },
  additionalDetail: {
    name: "additionalDetail",
    Label: "additionalDetailLabel",
    required: true,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Additional detail",
    value: "",
    status: -1,
  },
  addressaptnum: {
    name: "addressaptnum",
    Label: "addressaptnumLabel",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Apt Number",
    value: "",
    status: -1,
  },
  addresscity: {
    name: "addresscity",
    Label: "addresscityLabel",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "City",
    value: "",
    status: -1,
  },
  addresszipcode: {
    name: "addresszipcode",
    Label: "addresszipcodeLabel",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Zip Code",
    value: "",
    status: -1,
  },
  coordinatesx: {
    name: "coordinatesx",
    Label: "X:",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "X",
    value: "",
    status: -1,
  },
  coordinatesy: {
    name: "coordinatesy",
    Label: "Y:",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "Y",
    value: "",
    status: -1,
  },
  emailRequest: {
    name: "emailRequest",
    Label: "emailRequestLabel",
    required: false,
    disable: true,
    fontWeight: false,
    usepattern: false,
    errorLabel: "Email Request",
    value: "",
    status: -1,
  },
  isFollowUpCall: {
    name: "isFollowUpCall",
    Label: "isFollowUpCallLabel",
    required: false,
    disable: false,
    fontWeight: false,
    usepattern: false,
    errorLabel: "Follow-up Call?",
    value: "",
    status: -1,
  },
  emailRequestMsg: {
    name: "emailRequestMsg",
    Label: "Email a copy of this request",
    required: false,
    disable: false,
    fontWeight: true,
    usepattern: false,
    errorLabel: "",
    value: "",
    status: -1,
  },
  imageList: [],
  SRbuttonNewSearch: {
    name: "SRbuttonNewSearch",
    Label: "SRbuttonNewSearchLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false,
  },
  SRbuttonSearch: {
    name: "SRbuttonSearch",
    Label: "SRbuttonSearchLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false,
  },
  SRhyperlink: {
    name: "SRhyperlink",
    Label: "SRhyperlinkLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false,
  },
  SRlastName: {
    name: "SRlastName",
    Label: "SRlastNameLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false,
    value: "",
    status: -1,
  },
  SRnumber: {
    name: "SRnumber",
    Label: "SRnumberLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
  },
  SrRnumber: {
    name: "SRRnumber",
    Label: "SRnumberLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
  },
SrRstatus: {
    name: "SRRstatus",
    Label: "SRRstatusLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
  },
  SrRwoActualFinishDate: {
    name: "SRRwoActualFinishDate",
    Label: "SRRwoActualFinishDateLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
  },
  SrRwoActualStartDate: {
    name: "SRRwoActualStartDate",
    Label: "SRRwoActualStartDateLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
  },
  SrRwodescription: {
    name: "SRRwodescription",
    Label: "SRRwodescriptionLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
  },
  SrRwostatus: {
    name: "SRRwostatus",
    Label: "SRRwostatusLabel",
    required: false,
    disable: false,
    errorLabel: null,
    fontWeight: false,
    usepattern: false
    }
};
