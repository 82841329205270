import React, { useEffect } from "react";
import {
  Form,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import PRInput from "../common/PRInput";

export default function StatusForm(props) {
  useEffect(() => {

  }, []);

  return (
    <div className="maxElement">
      <Form
        onSubmit={(evt) => {
          evt.preventDefault();
          props.onSubmit();
        }}
      >
        <Col>
          <PRInput
            elem={props.state.SRnumber}
            validate={props.onchange}
            translate={props.translate}
            lang={props.lang}
          />

          <PRInput
            elem={props.state.SRlastName}
            validate={props.onchange}
            translate={props.translate}
            lang={props.lang}
          />

          <FormGroup>
                      <Button type="submit"
              color="info"
              onClick={(e) => {
                props.clickSearch(e);
              }}
            >
              {props.translate(props.lang, "SRbuttonSearchLabel")}
            </Button>
          </FormGroup>
        </Col>
      </Form>
    </div>
  );
}
