import React, { useState, useEffect } from "react";
import { sampleState } from "../common/defaultState";
import * as EmailValidator from "email-validator";
import HomeForm from "./home-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function Home(props) {
  const [Answer, setAnswer] = useState("");
  const [phoneRequired, setPhoneRequired] = useState(false);

  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
    const [fstate, setfState] = useState(sampleState);
    const [bodyState, setbodyState] = useState([]);

  const [imageOption, setImageOption] = useState("Y");
  const [prevFile, setPrevFile] = useState(null);

  const [curQ, setCurQ] = useState(null);
  const [curA, setCurA] = useState(null);
  const [curAall, setCurAall] = useState([]);
  const [branchingModel, setBranchingModel] = useState(false);

  const [allQ, setAllQ] = useState([]);
  const [allA, setAllA] = useState([]);

  const [selectedQA, setSelectedQA] = useState([]);
  const [isShow, setIsShow] = useState(false);

  const [gisAddreses, setGISAddreses] = useState([]);

  const [fields, setFields] = useState([]);


  const getQA = (ProblemSid) => {
    props.loadDiv("show");
    let currentQ = {};
    let allQ = [];
    let allA = [];
    clearQuestionAnswer();
    try {
      window
        .fetch(
          `GetQA?ProblemSid=${ProblemSid}&userSettingsId=${props.userSetId}&token=${props.token}`
        )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          try {
            props.loadDiv("hide");
              if (data.status === 0) {
                 
                  setBranchingModel(data.value.BranchingModel);
          
                  if (data.value.Questions) {

                      data.value.Questions.map(q => {
                          let answers = data.value.Answers.filter(a => a.QuestionId === q.QuestionId);
                          q.answers = answers;
                          if (answers && answers.length > 0
                          ) {
                              q.AnswerId = answers[0].AnswerId;
                              q.TellCaller = answers[0].TellCaller;
                          }
                      });
                   


                allQ = [...data.value.Questions];
                currentQ = allQ.find((e) => e.QuestionSequence === 1);
                setCurQ(currentQ);
                setAllQ(allQ);
              }
              if (data.value.Answers) {
                    allA = [...data.value.Answers];
                    let currentAall = [];
                    allA.forEach((e) => {
                      if (e.QuestionId === currentQ.QuestionId) currentAall.push(e);
                    });   

                    setCurAall(currentAall);
                    setCurA(currentAall[0]);
                    setAllA(allA);
               }
          

            } else {
              props.setAlerts(data.message, "danger");
            }
          } catch (error) {
            props.loadDiv("hide");
            console.log("Error: ", error);
            props.setAlert(error, "danger");
          }
        });
    } catch (error) {
      loadDiv("hide");
      console.log("Error: ", error);
      setAlert(error, "danger");
    }
  };

    useEffect(() => {
 
        setFields([
          /*  props.state.problemLocation.name,*/
            props.state.additionalDetail.name,
         /*   props.state.locationDetails.name,*/
            props.state.firstName.name,
            props.state.lastName.name,
            props.state.address.name,
            props.state.addressaptnum.name,
            props.state.addresscity.name,
            props.state.addresszipcode.name,
            props.state.phone.name,
            props.state.email.name,
            //props.state.coordinatesx.name,
            //props.state.coordinatesy.name,
            //props.state.isFollowUpCall.name,
            //props.state.emailRequest.name
        ]);

    setfState(props.state);
     
    setPhoneRequired(props.state["phone"].required);
    if (props.state["phone"].disable) {
      props.state["isFollowUpCall"].disable = true;
    }

    if (
      props.en &&
      props.fr &&
      props.translate(props.lang, "pageTitleLabel") != "pageTitleLabel"
    ) {
      document.title = props.translate(props.lang, "pageTitleLabel");
    }
    setIsShow(true);
    //    setbodyState(fstate.imageList);
  
  }, []);

  


  const listOfAddressCandidates = (value = false) => {
    if ($(".listAddress.stack-top"))
      $(".listAddress.stack-top").css("display", value ? "block" : "none");
  };

  const setAndValidate = (val, name) => {
    let status = -1;
    if (name === "isFollowUpCall" && !fstate["isFollowUpCall"].disable) {
      if (val && !phoneRequired) {
        fstate["phone"].required = true;
        if (!fstate["phone"].value || fstate["phone"].value === "") {
          fstate["phone"].status = 1;
        }
      } else {
        fstate["phone"].required = phoneRequired;
        if (!phoneRequired) {
          // status = 0;
          fstate["phone"].status = -1;
          var phoneno = new RegExp(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          );

          if (
            fstate["phone"].value &&
            fstate["phone"].value != "" &&
            !phoneno.test(fstate["phone"].value)
          ) {
            fstate["phone"].status = 1;
          } else {
            if (!fstate["phone"].value || fstate["phone"].value == "")
              fstate["phone"].status = -1;
          }
        }
      }
    }
    if (name === "email" && val && val.length > 0) {
      if (!EmailValidator.validate(val)) {
        status = 1;
      } else {
        status = 0;
      }
    } else {
      if (name === "phone") {
        // let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var phoneno = new RegExp(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        );
        // if (!val.match(phoneno)) {
        if (val && val != "" && !phoneno.test(val)) {
          status = 1;
        } else {
          status = 0;
        }
      } else {
        if (val) {
          status = 0;
        } else {
          if (fstate[name].required) status = 1;
        }
      }
    }
      if (name === "additionalDetail") {
       
          if (fstate[name].required && val.length>0) {
              status = 1;
              fstate[name].status = 1;
          } else {
              status = 0;
              fstate[name].status = 0;
          }
      }
      if (name && name.toLowerCase() != "imagelist") {
          fstate[name].value = val;
          fstate[name].status = status;
          setfState({ ...fstate });
      }
      if (name && name.toLowerCase() == "imagelist" && val == undefined) {
          fstate[name]=[];
      }
  };

  const getAddreses = (address) => {
    props.clearAlerts();

    if (
      address &&
      address.target &&
      address.target.value &&
      address.target.value.length > 0
    ) {
      fstate.problemLocation.valueDesc = address.target.value;
      let _address = address.target.value;
      setGISAddreses([]);

      let addressRequest = {
        address: _address,
        geocoderUrl: props.gcoderUrl,
        addressField: props.addressField,
        geomUrl: props.geomUrl,
        outSR: props.sp,
        userSettingsId: props.userSetId,
        cwtoken: props.token,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(addressRequest),
      };

      if (_address) {
        try {
          window
            .fetch("GeocodeXY", requestOptions)
            .then((resp) => {
              return resp.json();
            })
            .then((data) => {
              if (data.status !== 0) {
                {
                  props.setAlerts(data.message, "danger");
                  setGISAddreses([]);
                  return;
                }
              }

              if (data.value.candidates && data.value.candidates.length == 0) {
                props.setAlerts(
                  props.translate(props.lang, "NoDataFound"),
                  "warning"
                );
                setGISAddreses([]);
                return;
              }
              let ids = [];
              if (data && data.value && data.value.candidates)
                data.value.candidates.map((a) => {
                  ids.push(a.Address);
                });
              let candidates = [];
              if (ids && data && data.value && data.value.candidates) {
                ids.map((b) => {
                  data.value.candidates.map((a) => {
                    if (b === a.Address) {
                      let o = candidates.find((c) => c.Address == b);
                      if (!o) {
                        candidates.push(a);
                      }
                    }
                    // ids.push(a.address);
                  });
                });
              }

              if (
                $(".listAddress.stack-top") &&
                $("#problemLocation") &&
                $("#problemLocation").val().length > 0
              )
                $(".listAddress.stack-top").css("display", "block");

              setGISAddreses(candidates);
            });
        } catch (error) {
          props.loadDiv("hide");
          console.log("Error: ", error);
          props.setAlert(error, "danger");
        }
      } else {
        setGISAddreses([]);
      }
    } else {
      fstate.problemLocation.valueDesc = "";
        fstate.problemLocation.value = "";
      
      if ($(".listAddress.stack-top"))
        $(".listAddress.stack-top").css("display", "none");
    }
  };

  const validate = (e) => {
    if ($(".listAddress.stack-top"))
      $(".listAddress.stack-top").css("display", "none");
    const _value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

      if (e && e.target && e.target.name && e.target.name.toLowerCase() !=="imagelist")
          setAndValidate(_value, e.target.name);
      validationForm();
    };

    const validateSpan = (e, name) => {

        if (e && e.target && e.currentTarget && e.currentTarget.textContent)
            setAndValidate(e.currentTarget.textContent, name);
        validationForm();
    }

    const onchange = (e) => {
   
        try {
            if (e) {
                e.preventDefault();
                if (e && e.target && e.target.value) {
                    getQA(e.target.value);
                }
            } else {
                let currentQ = {};
                let allQ = [];
                let allA = [];
                clearQuestionAnswer();
            }
        } catch { }
        try { 
        fstate.problemLocation.value = "";
        fstate.problemLocation.valueDesc = "";


        setfState({
            ...fstate
        });
        ////setRefresh(refresh + 1);
        //    let _state = fstate;
        //    console.log("xxx", _state);

    } catch (e) { console.log(e); }
  };

  const nextQA = (e) => {
      setAnswer("");

      if ((!curA.Answer || curA.Answer === "Free-form Response" )) {
      return;
    }
    setfState({
      ...fstate,
      problemtype: { ...fstate["problemtype"], problemtypeq: false },
    });

    const object3 = { ...curQ, ...curA };
    let SelectedQA = [...selectedQA];
    SelectedQA.push(object3);

    setSelectedQA(SelectedQA);

    let nextQ = allQ.find((a) => a.QuestionId === curA.NextQuestionId);
    if (nextQ) {
      setCurQ(nextQ);
      let nextAall = [];
      allA.forEach((a) => {
        if (a.QuestionId === nextQ.QuestionId) {
          nextAall.push(a);
        }
      });
      setCurAall(nextAall);
      setCurA(nextAall[0]);
    } else {
      setCurQ(null);
      setCurA(null);
    }
  };

  const onSelectAnswer = (e, span=false) => {
    if(span==false){
    if (e && e.target && e.target.value) {
      if (e.target.tagName === "INPUT") {
        let answer = curAall.find(
          (el) => el.AnswerId.toString() === e.target.id.toString()
        );
        answer.Answer = e.target.value;
        setAnswer(e.target.value);
        setCurA(answer);
      } else {
        if (e.target.tagName === "DATE") {
        } else {
          let answer = curAall.find(
            (el) => el.AnswerId.toString() === e.target.value.toString()
          );
          setCurA(answer);
        }
      }
    }}else{
      if (e.currentTarget && e.currentTarget.textContent) {
        let answer = curAall.find(
          (el) => el.AnswerId.toString() === e.target.id.toString()
        );
        answer.Answer = e.currentTarget.textContent;
        setAnswer(e.currentTarget.textContent);
        setCurA(answer);

      }
    }
  };

    const onSelectAnswerL = (e, _allQ) => {
        if (e && e.target && e.target.value) {
          /*  setRefresh(refresh + 1);*/
        }
    };

    const validationForm = () => {
        let runCreate = true;
        let _state = fstate;

        Object.values(_state).forEach((a) => {
         
            /* if (a.valid) {*/
           /* console.log("Name=>"+ a.name + " value=>" + a.value +" required=>"+ a.required);*/
                let status = -1;

                switch (a.name) {
                    //case "problemtype":
                    //case "problemLocation":
                    //case "locationDetails":
                    //case "image":
                    //    break;
                    case "email":
                    case "phone":
                    case "firstName":
                    case "lastName":
                    case "address":
                    case "addressaptnum":
                    case "addresscity":
                    case "addresszipcode":
                    case "coordinatesx":
                    case "coordinatesy":

                        if ((a.required && !a.disable && !a.value) || a.status === 1) {
                            //a.status = 1;
                            status = 1;
                          //  console.log("Name=>" + a.name + " value=>" + a.value + " required=>" + a.required);
                        }
                        break;
                }

                if (status === 1) {
                    runCreate = false;
             
                }

        });

        if (runCreate === true) {
                //setBtnSubmitDisabled(true);
            try {
                document.getElementById("btnSubmit").classList.add("btn-info");
                document.getElementById("btnSubmit").classList.remove("btn-secondary");
                document.getElementById("btnSubmit").classList.remove("noAllowed");

            } catch { }

        }
        else {
            //setBtnSubmitDisabled(false);
            try {
                document.getElementById("btnSubmit").classList.remove("btn-info");
                document.getElementById("btnSubmit").classList.add("btn-secondary");
                document.getElementById("btnSubmit").classList.add("noAllowed");
            } catch { }
        }
        //console.log("AAA", fstate);
        //console.log("AAA", fstate.firstName.value);
        return runCreate;
    }

  const submitForm = () => {
    try {
      let runCreate = true;
      let _state = fstate;

      Object.values(_state).forEach((a) => {
        if (a.valid) {
          let status = -1;

          switch (a.name) {
            case "problemtype":
            case "problemLocation":
              case "locationDetails":
                  case "image":
                  break;
            case "email":
            case "phone":
              if ((a.required && !a.disable && !a.value) || a.status === 1) {
                a.status = 1;
                status = 1;
              }
              break;
            default: //some fields into state should not validate. They have status equal -1.
                if (a.required && !a.disable && !a.value) {                  
                    a.status = 1;
                    status = 1;
                }
              break;
          }

          if (status === 1) {
            runCreate = false;
            setfState({
              ...fstate,
              [a.name]: { ...fstate[a.name], status: 1 },
            });
          }
        }
      });

      if (runCreate) {
        createSR();
      } else {
        props.loadDiv("hide");
        setBtnSubmitDisabled(false);
      }
    } catch (ex) {
      console.log(ex);
      props.loadDiv("hide");
      setBtnSubmitDisabled(false);
    }
  };

  const createSR = () => {
    try {
      props.loadDiv("show", "In process...");
      setBtnSubmitDisabled(false);
        let qa = [];
        if (branchingModel) {
            selectedQA.forEach((e) => {
                qa.push({ AnswerId: e.AnswerId, AnswerValue: e.Answer });
            });
        } else {
            allQ.forEach((e) => {
                let answerid = e.AnswerId;
                e.answers.forEach(a => {
                    if (a.AnswerId.toString() === answerid.toString()) {
                        let AnswerValue = a.Answer;
                        if ((a.Answer === "Free-form Response" && a.AnswerFormat === "FREETEXT") ||
                            (a.Answer === "Date" && a.AnswerFormat === "DATE")) AnswerValue = "";

                            qa.push({ AnswerId: a.AnswerId, AnswerValue: AnswerValue });
                    }
                });
                
            });
        }

      let phone = fstate.phone.value;
      if (phone) {
        if (phone.indexOf("-") === -1 && phone.Length === 10) {
          phone =
            phone.substring(0, 3) +
            "-" +
            phone.substring(3, 3) +
            "-" +
            phone.substring(6, 4);
        }
      } else {
        phone = "";
      }
      //  let emailRequestVal = fstate.emailRequest.value ? "N" : "Y"; //if checked => no emailing

      let SRequest = {
        ProblemSid: fstate.problemtype.value ? fstate.problemtype.value : "",
        //Address: fstate.address.value ? fstate.address.value : "",
        Address: fstate.problemLocation.value
          ? fstate.problemLocation.value
          : "",
        AptNum: fstate.addressaptnum.value ? fstate.addressaptnum.value : "",
        City: fstate.addresscity.value ? fstate.addresscity.value : "",
        X: fstate.coordinatesx.value ? fstate.coordinatesx.value : 0.00,
        Y: fstate.coordinatesy.value ? fstate.coordinatesy.value : 0.00,
        Zip: fstate.addresszipcode.value ? fstate.addresszipcode.value : "",
        InitiatedByApp: props.initiatedByApp ? props.initiatedByApp : "",
        CallerFirstName: fstate.firstName.value ? fstate.firstName.value : "",
        CallerLastName: fstate.lastName.value ? fstate.lastName.value : "",
        CallerHomePhone: phone, // fstate.phone.value ? fstate.phone.value : "",
        CallerEmail: fstate.email.value ? fstate.email.value : "",
        //Location: fstate.problemLocation.value
        //  ? fstate.problemLocation.value
        //  : "",
        Location: fstate.locationDetails.value
          ? fstate.locationDetails.value
          : "",

        //Details: fstate.locationDetails.value
        //  ? fstate.locationDetails.value
        //  : "",
        Details: fstate.additionalDetail.value
          ? fstate.additionalDetail.value
          : "",

        //Comments: fstate.additionalDetail.value
        //  ? fstate.additionalDetail.value
        //  : "",
        // emailRequest: emailRequestVal, field name "emailRequest" is not exist. I populate Text1
        // Text1: emailRequestVal,
        emailRequest: fstate.emailRequest.value
          ? fstate.emailRequest.value
          : true,
        CallerIsFollowUpCall: fstate.isFollowUpCall.value
          ? fstate.isFollowUpCall.value
          : false,
        Answers: qa,
        token: props.token,
        userSettingsId: props.userSetId,
      };

        ////temp
        //console.log("SUBMIT", JSON.stringify(SRequest));
        //props.loadDiv("hide");
        //console.log("HM_submit file", fstate.imageList);
        //fstate.imageList.forEach((a) => {
        //    console.log("file", a);
        //});
        //return;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(SRequest),
      };
      try {
        window
          .fetch("CreateSR", requestOptions)
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            try {
              if (data.status === 0) {
                let message = `Service Request ${data.value} `;
                let messageType = "success";

                //-----validate preference status
                if (data.statusPreferences !== 0) {
                  message += `${data.messagePreferences}`;
                  messageType = "warning";
                }
                //----validate emeil status
                if (data.statusEmail !== 0) {
                  message += `${data.messageEmail}`;
                  messageType = "warning";
                }

                if (fstate.imageList && fstate.imageList.length > 0) {
                  uploadAttachments(data.value, message, messageType);
                } else {
                  clearForm();

                  props.loadDiv("hide");
                  props.setAlerts(message, messageType, false);

                  try {
                    window.scrollTo(0, 0);
                  } catch {}
                }
              } else {
                props.setAlerts(data.message, "danger");
                props.loadDiv("hide");
              }
            } catch {}
          });
      } catch (error) {
        props.loadDiv("hide");
        console.log("Error: ", error);
        props.setAlert(error, "danger");
      }
    } catch (ex) {
      console.log(ex);
      props.loadDiv("hide");
      props.setAlert(ex, "danger");
    }
  };

  const uploadAttachments = (SRequestId, message, messageType) => {
    let index = 0;
      fstate.imageList.forEach((a) => {

      try {
        let vFD = new FormData();
        let file = a.file;

          vFD.append("uploadFile", file);
        vFD.append("name", a.filename);
        vFD.append("requestId", SRequestId);
        vFD.append("token", props.token);
        vFD.append("userSettingsId", props.userSetId);
       
        const h = {};
        h.Accept = "application/json";

        const requestOptions = {
          method: "POST",
          headers: h,
          body: vFD,
        };

        window
          .fetch("UploadFileApi", requestOptions)
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            try {
              if (data.status === 0) {
              
                  ++index;
               
                if (index === fstate.imageList.length) {
                
                  clearForm();
                  props.loadDiv("hide");
                  props.setAlerts(message, messageType, false);
                }
              } else {
                message += `${data.message}`;
                messageType = "warning";
                props.setAlerts(message, messageType);
              
                props.loadDiv("hide");
              }
            } catch (e) {
              message += `${e}`;
              messageType = "warning";
              props.setAlerts(message, messageType);
          
              props.loadDiv("hide");
            }
          });
      } catch (e) {
        props.setAlerts(
          `On attach ${a.filename} to Service Request ${SRequestId}.  ${e}`,
          "danger"
        );
        props.loadDiv("hide");
      }
    });
  };

  const clearQuestionAnswer = () => {
    setCurQ(null);
    setCurA(null);
    setAllQ(null);
    setCurAall([]);
    setSelectedQA([]);
  };

  const clearImages = () => {
    setImageOption("N");
      setPrevFile(null);
      fstate.imageList=[];
      setfState({
          ...fstate
      });
  };

  const clearValues = () => {
    let _state = fstate;
      Object.values(_state).forEach((a) => {
          if (a.name && a.name.toLowerCase() != 'imagelist') {
              a.value = "";
              a.status = -1;
              setfState({
                  ...fstate,
                  [a.name]: { ...fstate[a.name], status: a.status },
              });
          }
    });
  };

  const clearForm = (e) => {
    props.loadDiv("show", "In process...");

    clearQuestionAnswer();
  
    clearValues();
    fstate.problemtype.valueDesc = "";
    fstate.problemLocation.valueDesc = "";
      setfState({
          ...fstate
      });
    

    props.loadDiv("hide");
      if (document.getElementById("screenshot_thumbnail_id"))document.getElementById("screenshot_thumbnail_id").src = "";
      if (document.getElementById("mapInstructions"))document.getElementById("mapInstructions").style.display = "none";
    
      if (document.getElementById("destroy_graphics")) document.getElementById("destroy_graphics").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' })); 

      for (let i = 0; i < fields.length; i++) {
          if (document.getElementById("core_state")) {
              document.getElementById("core_state").removeAttribute(fields[i].toLowerCase())
          }
         
      }
      clearImages();
      /*   document.body.setAttribute("_state", JSON.stringify(props.fstate));*/
      setbodyState([]);
      props.updateState(fstate);
  };


  const attach = (e) => {
    props.clearAlerts();
    let hasValidFiles = false;

    let limitFiles = props.limitFilesAttach.split(",");
    if (limitFiles.length > 0) {
      for (var i = 0; i < e.target.files.length; i++) {
        var file = e.target.files[i];

        for (var k = 0; k < limitFiles.length; k++) {
          if (file.name.endsWith(limitFiles[k])) {
            hasValidFiles = true;
          }
        }
        if (!hasValidFiles) {
          props.setAlerts("Unsupported file selected.", "danger");
          return;
        }
      }
    }

    if (props.maxFileSize > 0) {
      for (var i = 0; i < e.target.files.length; i++) {
        var file = e.target.files[i];
        if (file.size > props.maxFileSize) {
          props.setAlerts("Max file size exceeded.", "danger");
          return;
        }
      }
    }

    let blob = URL.createObjectURL(e.target.files[0]);

    let img = {
      filename: e.target.files[0].name,
      type: e.target.files[0].type,
      data: blob,
      preview: true,
      guid: props.Guid(),
      file: e.target.files[0],
    };
      if (fstate.imageList)
          fstate.imageList.push(img);
      else {
          fstate.imageList = [];
          fstate.imageList.push(img);
      }

      setfState({ ...fstate });
      setbodyState({ ...fstate.imageList });
     // document.body.setAttribute("_state", JSON.stringify(fstate.imageList));
      //console.log("test1", e.target.files[0]);
      //console.log("img", img);
      //console.log("fstate", fstate);
   };


   const deleteAttach = (e, guid) => {
        let _imageList = [...fstate.imageList];
        _imageList.splice(_imageList.findIndex(a => a.guid === guid), 1);
        fstate.imageList = [..._imageList];
       setfState({ ...fstate });
       setbodyState({ ..._imageList });
  /*     document.body.setAttribute("_state", JSON.stringify(fstate.imageList));*/
    };

  const previewAttach = (e, guid) => {
    let img = null;
    let _imageList = fstate.imageList ? [...fstate.imageList] : [];
    setPrevFile(null);
    _imageList.forEach((a) => {
      if (a.guid === guid) {
        img = a;
        a.preview = !a.preview;
      } else {
        a.preview = true;
      }
    });

    if (img.preview) setPrevFile(null);
    else setPrevFile(img.data);
  };

  const onChangeImageOption = (e) => {
    if (e && e.target && e.target.id) {
      let opt = e.target.id.replace("option", "");
      setImageOption(opt);
      if (opt === "N") {
        clearImages();
      }
    }
  };

    const onChangeImageName = (e, guid) => {
     
        if (e && e.target) {
            let name = e.target.value;
            let index = fstate.imageList.findIndex(a => a.guid === guid);
          
            if (index >= 0) {
               let _imageList = [...fstate.imageList]; 
                _imageList[index].mapFileName = name;
                _imageList[index].filename = name;
                fstate.imageList = [..._imageList];
                setfState({ ...fstate });
            }
        
        }
    }

  const onSearchChange = (selectedOption) => {
    if (selectedOption) {
      setfState({
        ...fstate,
        problemLocation: {
          ...fstate.problemLocation,
          value: selectedOption.label,
        },
        coordinatesx: { ...fstate.coordinatesx, value: selectedOption.x },
        coordinatesy: { ...fstate.coordinatesy, value: selectedOption.y },
      });
    } else {
      setfState({
        ...fstate,
        problemLocation: {
          ...fstate.problemLocation,
          value: "",
        },
        coordinatesx: { ...fstate.coordinatesx, value: 0.0 },
        coordinatesy: { ...fstate.coordinatesy, value: 0.0 },
      });
    }
  };

    const updateLocationAddress = (address, x, y) => { 

        let p = { ...fstate.problemLocation };
        p.value = address;
        p.valueDesc = address;
        p.status = 0;
        p.valid = true;
        setfState({
            ...fstate,
            problemLocation:p,
            coordinatesx: { ...fstate.coordinatesx, value:x },
            coordinatesy: { ...fstate.coordinatesy, value: y },
        });
  };


    const attachMapImage = (data) => {
    
    props.clearAlerts();
    setPrevFile(null);
    let mapFileNameOrg = props.translate(props.lang, "DefaultMapImageFileName");

    if (
      mapFileNameOrg === null ||
      mapFileNameOrg === "DefaultMapImageFileName"
    ) {
      props.setAlerts(
        props.translate(props.lang, "AttachedFileNameNotFound"),
        "danger"
      );
      props.loadDiv("hide");
      return;
        }
    
        saveAttachment(mapFileNameOrg, data);
  };

    const sreenshoot = (p) => {
        if (document.getElementById("screenshot")) document.getElementById("screenshot").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' }));

    }

    const saveAttachment = (mapFileNameNew, dataUrl) => {

        if (!dataUrl) {
            props.loadDiv("hide");
            return;
        }
        let mapFileName = mapFileNameNew;

        let index = -1;
        try {
            index = fstate.imageList.findIndex(a => a.guid === "mapguid");
            if (index >= 0) {
                mapFileName = fstate.imageList[index].mapFileName;

            }
        } catch { }
       
        let af = mapFileNameNew.split(".");

        var base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, "");

        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        let blob = new Blob(byteArrays, { type: "image/jpeg" });

        let img = {
            base64encodedstring: base64Data,
            filename: mapFileName,// mapFileNameNew,
            type: af[1], // "png", //"image/jpeg",//"jpg",//e.target.files[0].type,
            data: dataUrl,
            preview: true,
            guid: "mapguid",
            file: blob,
            mapFileName: mapFileName
        };
        try {
            //override location details
            if (document.getElementById("locationDetails").value) {
                fstate.locationDetails.value =
                    document.getElementById("locationDetails").value;
            }
        } catch (e) { console.log(e); }

        let _imageList = [...fstate.imageList];
        if (index >=0) {
            _imageList.splice(index, 1);
        }

        _imageList.push(img);
        fstate.imageList = [..._imageList];
        setfState({ ...fstate });
        setbodyState({ ...fstate.imageList});
        props.setAlerts(
            props.translate(props.lang, "AttachedWithName") + " " + mapFileNameNew,
            "info"
        );
        props.loadDiv("hide");
    }


  return (
      <>  
    
          {isShow ? (


              <HomeForm    
                  sreenshoot={sreenshoot}
                  bodyState={bodyState}
                  showMap={props.showMap}
                  hideMapByTemplateKeyword={props.hideMapByTemplateKeyword}
                  isShow={isShow}
                  fstate={fstate}
                  validate={validate}
                  validateSpan={validateSpan}
                  onchange={onchange}
                  onSelectAnswer={onSelectAnswer}
                  onSelectAnswerL={onSelectAnswerL}
                  nextQA={nextQA}
                  onChangeImageOption={onChangeImageOption}
                  attach={attach}
                  deleteAttach={deleteAttach}
                  previewAttach={previewAttach}
                  submitForm={submitForm}
                  clearForm={clearForm}
                  allQ={allQ}
                  curQ={curQ}
                  curA={curA}
                  curAall={curAall}
                  prevFile={prevFile}
                  imageOption={imageOption}
                  selectedQA={selectedQA}
                  onSearchChange={onSearchChange}
                  changePage={props.changePage}
                  translate={props.translate}
                  lang={props.lang}
                  useTitle={props.useTitle}
                  usecaptcha={props.usecaptcha}
                  useTranslation={props.useTranslation}
                  useFontOptions={props.useFontOptions}
                  useMapGraphics={props.useMapGraphics}
                  availableCreateTools={props.availableCreateTools}
                  useMapMeasurement={props.useMapMeasurement}
                  drawingRequiredForAllTypes={props.drawingRequiredForAllTypes}
                  drawingRequiredForTemplateKeyword={
                      props.drawingRequiredForTemplateKeyword
                  }
                  drawingRequiredForTemplateKeywordFound={
                      props.drawingRequiredForTemplateKeywordFound
                  }
                  markUpSelected={props.markUpSelected}
                  setupMarkUpSelected={props.setupMarkUpSelected}
                  initiatedByApp={props.initiatedByApp}
                  isHome={props.isHome}
                  problemTypes={props.problemTypes}
                  changeLang={props.changeLang}
                  recaptchaSiteKey={props.recaptchaSiteKey}
                  loadDiv={props.loadDiv}
                  btnSubmitDisabled={btnSubmitDisabled}
                  setBtnSubmitDisabled={setBtnSubmitDisabled}
                  Guid={props.Guid}
                  getQA={getQA}
                  problemTypesCommon={props.problemTypesCommon}
                  updateLocationAddress={updateLocationAddress}
                  setAndValidate={setAndValidate}
                  gisAddreses={gisAddreses}
                  extent={props.extent}
                  sp={props.sp}
                  geomUrl={props.geomUrl}
                  geomToken={props.geomToken}
                  gcoderUrl={props.gcoderUrl}
                  gcoderToken={props.gcoderToken}
                  addressField={props.addressField}
                  geomTokenRequired={props.geomTokenRequired}
                  gcoderTokenRequired={props.gcoderTokenRequired}
                  fontSize={props.fontSize}
                  changeFont={props.changeFont}
                  getAddreses={getAddreses}
                  clearAlerts={props.clearAlerts}
                  Answer={Answer}
                  limitFilesAttach={props.limitFilesAttach}
                  maxFileSize={props.maxFileSize}
                  openProblemsList={props.openProblemsList}
                  FeatureServiceUrl={props.FeatureServiceUrl}
                  userSetId={props.userSetId}
                  token={props.token}
                  setAlerts={props.setAlerts}
                  attachMapImage={attachMapImage}
                  setPrevFile={setPrevFile}
                  listOfAddressCandidates={listOfAddressCandidates}
                  updateMapLoad={props.updateMapLoad}
                  branchingModel={branchingModel}
                  webMapId={props.webMapId}
                  webMapUrl={props.webMapUrl}
                  fields={fields}
                  onChangeImageName={onChangeImageName}
                  listimages={fstate.imageList}
                  refresh={props.refresh}
                  validationForm={validationForm}
                  geoCodeZoomLevel={props.geoCodeZoomLevel}

               
        />
      ) : (
        ""
      )}
      
          <div className="versiondiv">
           
              <FontAwesomeIcon icon={faQuestionCircle} id="version" className="versionsign" 
                          onClick={(e) => {
                              props.setShowVersion(!props.showVersion);
                  }} />
              <span className="versionmsg"> {props.showVersion ? props.version : ''}</span>
          </div>
      {/* <div id="modal-attach" className="hideElement"></div> */}
    
    </>
  );
}

export default Home;
