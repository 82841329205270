import React, { useEffect } from "react";
import {Label, Input, FormGroup, Button } from "reactstrap";


export default function HomeQA(props) {
  useEffect(() => {
  // console.log(props);
  }, []);



  return (
      <>
            {props.allQ && props.allQ.length > 0 ? (
            <div
              className={
                props.fstate.problemtype &&
                props.fstate.problemtype.problemtypeq &&
                props.fstate.problemtype.problemtypeq
                  ? "rectangleWarning"
                  : "rectangle"
              }
            >
              {props.curQ ? (
                <FormGroup>
                  <Label> {props.translate(props.lang, "Question")}:</Label>{" "}
                  {/*        <Input*/}
                  {/*            type="textarea"*/}
                  {/*  id="question"*/}
                  {/*  disabled*/}
                  {/*  value={props.curQ.Question}*/}
                          {/*></Input>{" "}*/}
                          <span className="textarea" id="question" role="textbox" >{props.curQ.Question}</span>
                </FormGroup>
              ) : (
                ""
              )}

              {props.curA ? (
                <FormGroup>
                  <Label>{props.translate(props.lang, "Answer")}:</Label>
                  {props.curA.AnswerFormat !== "FREETEXT" &&
                  props.curA.AnswerFormat !== "DATE" &&
                  // props.curA.AnswerFormat !== "THISTEXT" &&
                  props.curAall ? (
                    <Input
                      type="select"
                      onChange={(e) => {
                        props.onSelectAnswer(e);
                      }}
                    >
                      {props.curAall.map((a) => (
                        <option key={a.AnswerId} value={a.AnswerId}>
                          {a.Answer}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    ""
                  )}
                         
                 {(props.curA.AnswerFormat === "FREETEXT") && props.curAall ? (
                             
                    //          <Input
                    //              type="text"
                    //  autoComplete="on"
                    //  id={props.curA.AnswerId}
                    //  name={props.curA.AnswerId}
                    //              key={props.curA.AnswerId}
                    //              valid={props.Answer.length!=0}
                    //              invalid={props.Answer.length == 0}
                    //              onChange={(e) => {
                    //               //   setAnswer(e.target.value);
                    //    props.onSelectAnswer(e);
                    //  }}
                    ///>
                              <span className="textareaEdit" id={props.curA.AnswerId} role="textbox" contentEditable 
                                  name={props.curA.AnswerId}
                                  key={props.curA.AnswerId}
                           
                                  onInput={(e) => {

                                      if (e.currentTarget.textContent && e.currentTarget.textContent.length > 0) {
                                          e.currentTarget.classList.add("is-valid");
                                          e.currentTarget.classList.add("form-control");
                                      } else {
                                          e.currentTarget.classList.remove("is-valid");
                                          e.currentTarget.classList.remove("form-control");
                                      }
                                    
                                        props.onSelectAnswer(e,true);
                                  }} >

                              </span>
                  ) : (
                    ""
                  )}

                  {props.curA.AnswerFormat === "DATE" && props.curAall ? (
                    <Input
                      type="date"
                      name="date"
                      id={props.curA.AnswerId}
                      placeholder=""
                                  onChange={(e) => {
                                      let val = e.target.value.toString();
                                   

                                      if (val && val.toLowerCase() != "date") {
                                          e.currentTarget.classList.add("is-valid");
                                          e.currentTarget.classList.add("form-control");
                                         
                                      } else {
                                          e.currentTarget.classList.remove("is-valid");
                                          e.currentTarget.classList.remove("form-control");
                                      }
                        props.onSelectAnswer(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </FormGroup>
              ) : (
                ""
              )}

              {props.curA &&
              props.curA.TellCaller &&
              props.curA.TellCaller.length > 0 ? (
                
                <FormGroup>
                <span className="qa-label">{props.curA.TellCaller}</span>
               
                </FormGroup>
              ) : (
                ""
              )}
              {props.curA && props.curQ ? (
                <Button 
                  color="outline-info"
                          onClick={(e) => {
                              if (props.curA.Answer !== "Date") {
                                  props.nextQA(e);
                              }
                  }}
                >
                  {props.translate(props.lang, "Next")}
                </Button>
              ) : (
                ""
              )}

              {!props.curQ &&
              !props.curA &&
              props.selectedQA &&
              props.selectedQA.length > 0
                ? props.selectedQA.map((a) => (
                    <FormGroup key={props.Guid()}>
                      <Label>
                        <span className="qa-label" key={props.Guid()}>
                          {props.translate(props.lang, "Question")}:
                        </span>
                      </Label>
                      <span className="qa-input" key={props.Guid()}>{a.Question}</span>
                      <p>
                        <Label>
                          <span className="qa-label" key={props.Guid()}>
                            {props.translate(props.lang, "Answer")}:
                          </span>
                        </Label>
                        <span className="qa-input" key={props.Guid()}>{a.Answer}</span>
                      </p>
                    </FormGroup>
                  ))
                : ""}
            </div>
          ) : (
            ""
          )}
    
    
   
      </>
  );
}
