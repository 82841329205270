import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
    useReducer,
    useImperativeHandle
} from "react";
import { loadModules } from "esri-loader";

import {
  FormGroup,
  Label,
  FormFeedback,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
} from "reactstrap";

import $ from "jquery";

export const WebMapView = (props) => {

    if (document.getElementById("pinGeocoder")) {
        document.getElementById("pinGeocoder").setAttribute("problemLocation", props.fstate.problemLocation.value);
        document.getElementById("pinGeocoder").style.color = document.getElementById("pinGeocoder").getAttribute("problemLocation") ? "green" : "red";
    }
    const mapRef = useRef(null);

    var markupEvent = false;
    var pointGraphic = null;

    useEffect(() => {
        loadModules(
            [
                "esri/Map",
                "esri/views/MapView",
                "esri/widgets/Locate",
                "esri/Graphic",
                "esri/layers/GraphicsLayer",
                "esri/geometry/Extent",
                "esri/geometry/SpatialReference",
                "esri/geometry/Point",
                "esri/geometry/support/webMercatorUtils",
                "esri/widgets/ScaleBar",
                "esri/layers/FeatureLayer",
                "esri/widgets/Feature",
                "esri/core/promiseUtils",
                "esri/PopupTemplate",
                "esri/widgets/FeatureTable",
                "esri/views/draw/Draw",
                "esri/geometry/geometryEngine",
                "esri/widgets/Sketch",
                "esri/symbols/SimpleMarkerSymbol",
                "esri/symbols/SimpleLineSymbol",
                "esri/symbols/SimpleFillSymbol",
                "esri/symbols/TextSymbol",
                "esri/geometry/Polygon",
                "esri/widgets/Measurement",
                "esri/widgets/BasemapGallery",
                "esri/WebMap",

            ],
            { css: true }
        ).then(
            ([
                ArcGISMap,
                MapView,
                Locate,
                Graphic,
                GraphicsLayer,
                Extent,
                SpatialReference,
                Point,
                webMercatorUtils,
                ScaleBar,
                FeatureLayer,
                Feature,
                promiseUtils,
                PopupTemplate,
                FeatureTable,
                Draw,
                geometryEngine,
                Sketch,
                SimpleMarkerSymbol,
                SimpleLineSymbol,
                SimpleFillSymbol,
                TextSymbol,
                Polygon,
                Measurement,
                BasemapGallery,
                WebMap,

            ]) => {

                props.updateMapLoad(false);

                var graphicsLayer = new GraphicsLayer();
                var graphicsLayerArrow = new GraphicsLayer(); //Keep data for arrow graphics
                var featureLayer = null;
                var markupEvent = false;
                var markupArrow = false;
                var measurmentEvent = false;

                const features = [];

                var _extent = null;
                var pointWkid = null;
                let centerPoint = null;

                if (props.extent != null) _extent = Extent.fromJSON(props.extent);

                if (props.extent != null)
                    pointWkid = _extent["spatialReference"]["wkid"];
                else pointWkid = 102100;

                if (props.sp != -1) {
                    pointWkid = props.sp;
                }

                if (props.extent != null) {
                    centerPoint = new Point({
                        x: _extent.center.x.toFixed(4),
                        y: _extent.center.y.toFixed(4),
                        spatialReference: { wkid: pointWkid },
                    });
                }

                try {
                    if (props.webMapId != "" && props.webMapUrl != "") {

                        var map = new WebMap({
                            portalItem: {
                                id: props.webMapId,
                                extent: props.extent
                            }
                        });

                        var view = new MapView({
                            map: map,
                            container: mapRef.current,
                            extent: props.extent,
                           /* zoom: 13*/
                        });

                        map.add(graphicsLayer);
                        map.add(graphicsLayerArrow);
                    }
                    else {

                        var map = new ArcGISMap({
                            basemap: "topo-vector",
                            spatialReference: new SpatialReference({ wkid: pointWkid }),
                        });
                        var view = new MapView({
                            container: mapRef.current,
                            map: map,
                            extent: props.extent,
                          /*  zoom: 13,*/
                        });

                        map.add(graphicsLayer);
                        map.add(graphicsLayerArrow);
                    }
                } catch {
                    var map = new ArcGISMap({
                        basemap: "topo-vector",
                        spatialReference: new SpatialReference({ wkid: pointWkid }),
                    });
                    var view = new MapView({
                        container: mapRef.current,
                        map: map,
                        extent: props.extent,
                     /*   zoom: 13,*/
                    });

                    map.add(graphicsLayer);
                    map.add(graphicsLayerArrow);
                }

                //Add Base Map Gallery tool
                let basemapGallery = new BasemapGallery({
                    view: view,
                });
                view.ui.add(basemapGallery, {
                    position: "top-right",
                });
                basemapGallery.when(() => {
                    if (
                        document.querySelectorAll(
                            ".esri-component.esri-basemap-gallery.esri-widget"
                        )
                    )
                        document.querySelectorAll(
                            ".esri-component.esri-basemap-gallery.esri-widget"
                        )[0].style.display = "none";
                });


                const eventHandler = (evt) => {
                    props.listOfAddressCandidates();

                    if (!document.getElementById("problemtype").value) {
                        props.setAlerts(
                            props.translate(
                                document.getElementById("sr_lang").getAttribute("langVal"),
                                "SelectAProblemTypeMsg"
                            )
                        );
                        return;
                    }
                    if (markupEvent == false && measurmentEvent === false) {
                        document
                            .getElementById("popup")
                            .setAttribute("point", JSON.stringify(evt.mapPoint));
                        getAddressFromXY(evt.mapPoint);
                    }
                }


                var mouseEvtHandler = null;

                var elementPin = document.createElement("div");
                elementPin.id = "pinGeocoder";
                elementPin.className = "esri-icon-map-pin esri-widget--button";
                elementPin.style.height = "44px";
                elementPin.style.width = "44px";
                elementPin.style.backgroundColor = "lightgray";
                elementPin.style.color = "red";
                elementPin.title = props.translate(props.lang, "BtnMapGeocoder");

                const enableDisablePinBtn = (elem, option = true) => {

                    if (option) {
                        elem.style.backgroundColor = "white";
                        // elem.style.color = (props.fstate.problemLocation.value)?"green":"red";
                        if (document.getElementById("toolbarDiv")) document.getElementById("toolbarDiv").style.display = "none";
                        if (document.getElementsByClassName("esri-sketch") && document.getElementsByClassName("esri-sketch")[0]) document.getElementsByClassName("esri-sketch")[0].style.display = "none";


                    } else {

                        if (document.getElementById("toolbarDiv"))
                            document.getElementById("toolbarDiv").style.display = "flex";

                        if (document.getElementsByClassName("esri-sketch") && document.getElementsByClassName("esri-sketch")[0])
                            document.getElementsByClassName("esri-sketch")[0].style.display = "flex";

                        elem.style.backgroundColor = "lightgray";
                        // elem.style.color = "black"; (props.fstate.problemLocation.value) ? "green" : "red";// "black";
                    }

                    elem.style.color = document.getElementById("pinGeocoder").getAttribute("problemLocation") ? "green" : "red";
                }

                var mouseEvtHandler = null;

                elementPin.addEventListener("click", function (evt) {
                    evt.stopPropagation();

                    if (mouseEvtHandler) {
                        enableDisablePinBtn(evt.srcElement, false);
                        mouseEvtHandler.remove();
                        mouseEvtHandler = null;

                    } else {
                        mouseEvtHandler = view.on("click", eventHandler);
                        enableDisablePinBtn(evt.srcElement);

                    }

                });
                view.ui.add(elementPin, "top-right");// "bottom-right");





                // Add button to show/hide Base Map Gallery
                var element = document.createElement("div");
                element.className =
                    "esri-icon-basemap esri-widget--button esri-widget esri-interactive";
                element.title = "Base Map Gallery";
                element.addEventListener("click", function (evt) {
                    evt.stopPropagation();

                    let display_basemap = document.querySelectorAll(
                        ".esri-component.esri-basemap-gallery.esri-widget"
                    )[0].style.display;
                    if (display_basemap == "none")
                        document.querySelectorAll(
                            ".esri-component.esri-basemap-gallery.esri-widget"
                        )[0].style.display = "block";
                    else
                        document.querySelectorAll(
                            ".esri-component.esri-basemap-gallery.esri-widget"
                        )[0].style.display = "none";
                });
                view.ui.add(element, "top-left");




                //Add Scale Bar tool
                var scaleBar = new ScaleBar({
                    view: view,
                    unit: "dual", // The scale bar displays both metric and non-metric units.
                });
                view.ui.add(scaleBar, {
                    position: "bottom-left",
                });

                //Add Locate Tool
                var locate = new Locate({
                    view: view,
                    useHeadingEnabled: false,
                    goToOverride: function (view, options) {
                        options.target.scale = 1800; // Override the default map scale
                        return view.goTo(options.target);
                    },
                });
                view.ui.add(locate, "top-left");

                //Setup for takeScreenshot
                let pixelRatio = 2;// window.devicePixelRatio;

                var options = {
                    width: 2048 * pixelRatio,
                    height: 1024 * pixelRatio,
                    quality: 100,
                    format: "jpg",
                    ignoreBackground: false,
                };

                //Add Feature Layer begin
                if (props.FeatureServiceUrl) {
                    const allData = async () => {
                        const response = await fetch(
                            props.FeatureServiceUrl +
                            "/query?where=&outFields=*&returnGeometry=true&returnIdsOnly=false&f=json"
                        );
                        const myJson = await response.json(); //extract JSON from the http response
                        let i = 0;
                        for (const element of myJson.features) {
                            let row = element.attributes;
                            row.id = i;
                            row.g = element.geometry;
                            ++i;
                        }
                    };
                    allData();

                    featureLayer = new FeatureLayer({
                        url: props.FeatureServiceUrl,
                        outFields: ["*"],
                        map: view.map,
                        spatialReference: view.spatialReference,
                        popupTemplate: {
                            title: "Request Id: {REQUESTID}",
                            content: "Problem Address:  {ProbAddress}",
                        },
                    });

                    featureLayer.when(() => {
                        view.goTo(featureLayer.fullExtent);
                    });

                    map.add(featureLayer);



                    // Create the feature table
                    const featureTable = new FeatureTable({
                        view: view, // required for feature highlight to work
                        layer: featureLayer,
                        visibleElements: {
                            // autocast to VisibleElements
                            menuItems: {
                                clearSelection: true,
                                refreshData: true,
                                toggleColumns: true,
                                selectedRecordsShowAllToggle: true,
                                selectedRecordsShowSelectedToggle: true,
                                zoomToSelection: true,
                            },
                        },
                        // autocast to FieldColumnConfigs
                        fieldConfigs: [
                            {
                                name: "REQUESTID",
                                label: "Request Id",
                                direction: "asc",
                            },
                            {
                                name: "ProbAddress",
                                label: "Problem Address",
                            },
                        ],
                        container: document.getElementById("tableDiv"),
                    });
                    document.getElementById("tableDiv").innerHTML = "";

                    // Listen for the table's selection-change event
                    featureTable.on("selection-change", (changes) => {
                        // If the selection is removed, remove the feature from the array
                        changes.removed.forEach((item) => {
                            const data = features.find((data) => {
                                return data.feature === item.feature;
                            });
                            if (data) {
                                features.splice(features.indexOf(data), 1);
                                view.goTo(data.graphic);
                            }
                        });

                        // If the selection is added, push all added selections to array
                        changes.added.forEach((item) => {
                            const feature = item.feature;
                            features.push({
                                feature: feature,
                            });
                        });
                    });
                } //Add Feature Layer end



                var fillSymbol = new SimpleFillSymbol({
                    color: [227, 139, 79, 0.8],
                    outline: {
                        color: [255, 255, 255],
                        width: 1,
                    },
                });

                function makeArrowGeometry(
                    screenPointStart,
                    screenPointEnd,
                    view,
                    symbol
                ) {
                    let start = view.toMap(screenPointStart),
                        end = view.toMap(screenPointEnd);

                    let dist = start.distance(end),
                        dx = end.x - start.x,
                        dy = end.y - start.y,
                        rads = Math.atan2(dx, dy);

                    if (rads < 0) {
                        rads = Math.abs(rads);
                    } else {
                        rads = 2 * Math.PI - rads;
                    }

                    let degrees = (rads * 180) / Math.PI;

                    let width = 0.2 * dist; // 1/5, can adjust

                    let bottomLeft = [start.x - width / 2, start.y];
                    let bottomRight = [start.x + width / 2, start.y];
                    let midLeft = [bottomLeft[0], [bottomLeft[1] + dist - width]];
                    let midRight = [bottomRight[0], [bottomRight[1] + dist - width]];
                    let edgeLeft = [midLeft[0] - width / 2, midLeft[1]];
                    let edgeRight = [midRight[0] + width / 2, midRight[1]];
                    let point = [start.x, start.y + dist];

                    let poly = new Polygon({
                        rings: [
                            [
                                point,
                                edgeLeft,
                                midLeft,
                                bottomLeft,
                                bottomRight,
                                midRight,
                                edgeRight,
                                point,
                            ],
                        ],
                        spatialReference: { wkid: 102100 },
                    });

                    let rotatedPoly = geometryEngine.rotate(poly, degrees, start);

                    return new Graphic({
                        geometry: rotatedPoly,
                        symbol: symbol,
                    });
                }

                //Add Sketch tool
                let sketch = new Sketch({
                    layer: graphicsLayer,
                    view: view,
                    creationMode: "single", //"update",
                    availableCreateTools: props.availableCreateTools, // ["point","polyline","polygon","rectangle", "circle"],
                    defaultUpdateOptions: {
                        enableRotation: false,
                        //toggleToolOnClick: true,
                        //enableScaling: false,
                        //enableZ: false,
                        multipleSelectionEnabled: false,
                        toggleToolOnClick: false,
                    },

                    visibleElements: {
                        selectionTools: {
                            "lasso-selection": false,
                            "rectangle-selection": false,
                        },
                        settingsMenu: false,
                    },
                });
                if (props.useMapGraphics) {
                    view.ui.add(sketch, {
                        position: "bottom-right",
                    });


                    sketch.on("create", function (event) {
                        if (markupArrow) markupArrow = false;
                        //Possible Values:"start"|"active"|"complete"|"cancel"
                        if (event.state === "complete" || event.state === "cancel") {
                            markupEvent = false;
                            let _graphicsLayer = [];
                            graphicsLayer.graphics.forEach(function (graphic) {
                                if (graphic != pointGraphic) {
                                    //console.log("pointGraphic", pointGraphic);
                                    _graphicsLayer.push(graphic);
                                }
                            });
                            props.setupMarkUpSelected(_graphicsLayer);
                            event.graphic.symbol.outline.color = [255, 0, 0, 1];
                            /* props.pageTwoValidationClick();*/
                        }
                        if (event.state === "start") {
                            markupEvent = true;
                            // props.setupMarkUpSelected([]);
                        }
                        //if (event.state === "active") { console.log("sketch active"); }

                    });

                    sketch.on("update", function (event) {
                        if (event.state === "complete") {
                            let _graphicsLayer = [];
                            graphicsLayer.graphics.forEach(function (graphic) {
                                if (graphic != pointGraphic) {
                                    //console.log("pointGraphic", pointGraphic);
                                    _graphicsLayer.push(graphic);
                                }
                            });
                            props.setupMarkUpSelected(_graphicsLayer);

                            /*  props.pageTwoValidationClick();*/
                        }
                    });

                    if (props.availableCreateTools.indexOf("arrow") != -1) {
                        var elementDrawArrow = document.createElement("div");
                        elementDrawArrow.id = "sketch-arrow";

                        //elementDrawArrow.style.borderRight = "1px solid lightgray";
                        elementDrawArrow.style.margin = "0px 5px 0px 0px";
                        elementDrawArrow.className =
                            "esri-icon-overview-arrow-top-left esri-widget--button esri-widget esri-interactive customBtn";
                        view.ui.add(elementDrawArrow, "bottom-right");
                        elementDrawArrow.addEventListener("click", function (evt) {
                            evt.stopPropagation();
                            markupArrow = true;
                            //if (markupEvent === false) return;
                            view.on("drag", (e) => {
                                e.stopPropagation();
                                if (markupArrow === false) return;
                                if (e.origin.x !== e.x && e.origin.y !== e.y) {
                                    let arrow = makeArrowGeometry(
                                        e.origin,
                                        { x: e.x, y: e.y },
                                        view,
                                        fillSymbol
                                    );
                                    graphicsLayerArrow.remove(arrow);
                                    graphicsLayerArrow.add(arrow);

                                }
                            });
                        });
                        document
                            .getElementById("sketch-arrow")
                            .setAttribute("title", "Draw arrow");
                    }

                    sketch.when(() => {
                        $("#sketch-arrow")
                            .detach()
                            .appendTo($(".esri-sketch__panel[role='toolbar']"));
                        if (document.querySelector(".esri-sketch"))
                            document.querySelector(".esri-sketch").style.display = "none";
                    });

                }

                if (props.useMapMeasurement) {
                    //toolbar holder for measument tool (for keep measurement tool on map view)
                    var al = document.getElementById("toolbarDiv");

                    view.ui.add(al, "bottom-right");

                    // Set-up event handlers for buttons and click events
                    // const switchButton = document.getElementById("switch-btn");
                    const distanceButton = document.getElementById("distance");
                    const areaButton = document.getElementById("area");
                    const clearButton = document.getElementById("clear");

                    if (distanceButton) distanceButton.addEventListener("click", () => {
                        measurmentEvent = true;
                        distanceMeasurement();
                    });
                    if (areaButton) areaButton.addEventListener("click", () => {
                        measurmentEvent = true;
                        areaMeasurement();
                    });
                    if (clearButton) clearButton.addEventListener("click", () => {
                        measurmentEvent = false;
                        clearMeasurements();
                    });

                    const measurement = new Measurement({
                        viewModel: {
                            view: view,
                            // activeTool: "area"
                        },
                    });

                    measurement.on("measure-end", function (event) {
                        measurmentEvent = false;
                    });

                    measurement.watch("viewModel.state", function (state) {
                        if (state === "ready") {
                            measurmentEvent = true;
                        }
                        if (state === "measured") {
                            measurmentEvent = false;
                        }
                    });

                    measurement.when(() => {
                        document.getElementById("toolbarDiv").style.display = "flex"; //horizontal layout
                        document.getElementById("toolbarDiv").style.border =
                            "5px solid white";
                        document.getElementById("toolbarDiv").style.height = "45px";
                    });

                    // Call the appropriate DistanceMeasurement2D or DirectLineMeasurement3D
                    function distanceMeasurement() {
                        measurmentEvent = true;
                        // if (markupEvent === false) return;

                        if (measurement) measurement.activeTool = "distance"; // type.toUpperCase() === "2D" ? "distance" : "direct-line";
                        if (distanceButton) distanceButton.classList.add("active");
                        if (areaButton) areaButton.classList.remove("active");
                    }

                    // Call the appropriate AreaMeasurement2D or AreaMeasurement3D
                    function areaMeasurement() {
                        measurmentEvent = true;
                        //  if (markupEvent === false) return;
                        if (measurement) measurement.activeTool = "area";
                        if (distanceButton) distanceButton.classList.remove("active");
                        if (areaButton) areaButton.classList.add("active");
                    }

                    // Clears all measurements
                    function clearMeasurements() {
                        measurmentEvent = false;
                        /* if (markupEvent === false) return;*/
                        if (distanceButton) distanceButton.classList.remove("active");
                        if (areaButton) areaButton.classList.remove("active");
                        if (measurement) measurement.clear();
                    }

                    // Clear any measurements that had been drawn
                    clearMeasurements();
                }

                view.on("pointer-move", function (event) {
                    view.hitTest(event).then(function (response) {
                        try {
                            if (response.results.length) {
                                var graphic = response.results.filter(function (result) {
                                    // check if the graphic belongs to the layer of interest
                                    return result.graphic.layer === featureLayer;
                                })[0].graphic;
                                view.popup.open({
                                    location: graphic.geometry.centroid,
                                    features: [graphic],
                                });

                                featureTable.selectRows(graphic);
                            } else {
                                view.popup.close();
                            }
                        } catch { }
                    });
                });

                const centerYRequest = {
                    inSR: pointWkid,
                    outSR: 102100,
                    geometries: JSON.stringify({
                        geometryType: "esriGeometryPoint",
                        geometries: [centerPoint],
                    }),
                    geometryUrl: props.geomUrl,
                    geometryToken: props.geomToken,
                    geomTokenRequired: props.geomTokenRequired,
                    userSettingsId: props.userSetId,
                    cwtoken: props.token,
                };

                const requestOptionsCenter = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(centerYRequest),
                };
                try {
                    window
                        .fetch("GetExtentCenter", requestOptionsCenter)
                        .then((resp) => {
                            return resp.json();
                        })
                        .then((data) => {
                            try {
                                if (data.status !== 0) {
                                    props.setAlerts(data.message, "danger");
                                } else {
                                    if (data && data.value) {
                                        let value = data.value;

                                        let _wp = webMercatorUtils.xyToLngLat(value["x"], value["y"]);
                                        view.center = _wp;
                                    }
                                }
                            } catch (error) {
                                props.loadDiv("hide");
                                console.log("Error: ", error);
                                props.setAlert(error, "danger");
                            }
                        });
                } catch (error) {
                    props.loadDiv("hide");
                    console.log("Error: ", error);
                    setAlert(error, "danger");
                }

                const getAddressFromXY = (point) => {
                  
                    props.loadDiv("show");
                    let _wp = webMercatorUtils.xyToLngLat(point["x"], point["y"]);

                    drawPoint(_wp[0], _wp[1]);


                    let pointjson = {
                        geometryType: "esriGeometryPoint",
                        geometries: [point.toJSON()],
                    };
                    let mapWkid = point.toJSON()["spatialReference"]["wkid"];
  


                    props.getLocation(point, pointjson, mapWkid, pointWkid);
      

                };

                var elRunXYfromAddress = document.createElement("div");
                elRunXYfromAddress.id = "elementRunXYfromAddress";
                elRunXYfromAddress.className =
                    "esri-icon-overview-arrow-top-left esri-widget--button esri-widget esri-interactive";
                view.ui.add(elRunXYfromAddress, "bottom-right");
                $("#elementRunXYfromAddress").css("display", "none");


                elRunXYfromAddress.addEventListener("keyup", function (e) {
                    e.stopPropagation();
                    let sellectedAddress = document
                        .getElementById("elementRunXYfromAddress")
                        .getAttribute("sellectedAddress");
                    let X = parseFloat(
                        document.getElementById("elementRunXYfromAddress").getAttribute("x")
                    );
                    let Y = parseFloat(
                        document.getElementById("elementRunXYfromAddress").getAttribute("y")
                    );

                    let _wp = webMercatorUtils.xyToLngLat(X, Y);

                    drawPoint(_wp[0], _wp[1]);

                    document
                        .getElementById("elementRunXYfromAddress")
                        .setAttribute("sellectedAddress", "");
                    document
                        .getElementById("elementRunXYfromAddress")
                        .setAttribute("x", "-1");
                    document
                        .getElementById("elementRunXYfromAddress")
                        .setAttribute("y", "-1");

                    var point = new Point({
                        x: X,
                        y: Y,
                        spatialReference: { wkid: pointWkid },
                    });
                    let mapWkid = pointWkid;

                    props.getAddress(point, pointWkid, mapWkid, sellectedAddress, X, Y);
                });



                function drawPoint(longitude, latitude) {
                    if (pointGraphic) {
                        graphicsLayer.remove(pointGraphic);
                    }

                    var point = {
                        type: "point",
                        longitude: longitude,
                        latitude: latitude,
                    };

                    var simpleMarkerSymbol = {
                        type: "simple-marker",
                        color: [226, 119, 40], // orange
                        outline: {
                            color: [255, 255, 255], // white
                            width: 1,
                        },
                    };

                    pointGraphic = new Graphic({
                        geometry: point,
                        symbol: simpleMarkerSymbol,
                    });


                    graphicsLayer.add(pointGraphic);
                    //.ZoomTo(pointGraphic);
                  //  view.centerAndZoom(pointGraphic, 12);

                    if (mouseEvtHandler) {
                        mouseEvtHandler.remove();
                        mouseEvtHandler = null;
                    }
                    let pin = document.getElementById("pinGeocoder");
                    enableDisablePinBtn(pin, false);

                    try {
                        view.goTo({
                            target: pointGraphic,
                            zoom: props.geoCodeZoomLevel
                        });
                    } catch (e) { console.log(e); }

                }



                const popupAddressClick = (e) => {
                    e.stopPropagation();
                    let address_x = parseFloat(
                        document.getElementById("popup_address").getAttribute("address_x")
                    );
                    let address_y = parseFloat(
                        document.getElementById("popup_address").getAttribute("address_y")
                    );

                    let _wp_a = webMercatorUtils.xyToLngLat(address_x, address_y);
                    view.center = _wp_a;

                    drawPoint(_wp_a[0], _wp_a[1]);
                    props.loadDiv("hide");

                };



                const screenShootClick = (e) => {
                    e.stopPropagation();

                    if (view != null) {
                        try {
                            view.takeScreenshot(options).then(function (screenshot) {
                                try {
                                    document.getElementById("screenshot_thumbnail_id").src =
                                        screenshot.dataUrl;
                                    //console.log("from map", props);
                                    //console.log("{props.bodyState}", props.bodyState);
                                    props.saveScreenshot(screenshot.dataUrl);
                                } catch (ex) {
                                    console.log(ex);
                                    props.loadDiv("hide");
                                }
                            });
                        } catch { }
                    }
                }



                const destroyGraphics = () => {
                    graphicsLayer.removeAll();
                    graphicsLayerArrow.removeAll();
                    props.setupMarkUpSelected([]);
                };


                view
                    .when(() => {
                        document.getElementById("screenshot").addEventListener("keyup", screenShootClick, true);
                        document.getElementById("destroy_graphics").addEventListener("keyup", destroyGraphics, true);
                        document.getElementById("popup_address").addEventListener("keyup", popupAddressClick, true);
                        // document.getElementById("popup").addEventListener("keyup", popupClick, true);


                        if (props.drawingRequiredForAllTypes === false) {

                            let problemValue = document.getElementById("problemtype").value;

                            if (problemValue) {

                                let prob = props.problemTypes.find(
                                    (f) => f.description === problemValue
                                );

                                if (
                                    prob &&
                                    prob.keywords &&
                                    prob.keywords.indexOf(
                                        props.drawingRequiredForTemplateKeyword
                                    ) != -1
                                ) {

                                    setTimeout(function () {
                                        document
                                            .getElementById("problemSelectLabel")
                                            .setAttribute(
                                                "drawingRequiredForTemplateKeywordFound",
                                                true
                                            );
                                        if (document.querySelector(".esri-sketch"))
                                            document.querySelector(".esri-sketch").style.display =
                                                "block";

                                    }, 500);
                                } else {
                                    if (document.querySelector(".esri-sketch"))
                                        document.querySelector(".esri-sketch").style.display =
                                            "none";

                                    if (document.getElementById("problemSelectLabel")) {
                                        document
                                            .getElementById("problemSelectLabel")
                                            .setAttribute(
                                                "drawingRequiredForTemplateKeywordFound",
                                                false
                                            );
                                    }
                                }
                            } else {
                                if (document.querySelector(".esri-sketch"))
                                    document.querySelector(".esri-sketch").style.display = "none";

                            }
                        } else {

                            setTimeout(function () {

                                document
                                    .getElementById("problemSelectLabel")
                                    .setAttribute(
                                        "drawingRequiredForTemplateKeywordFound",
                                        true
                                    );
                                if (document.querySelector(".esri-sketch"))
                                    document.querySelector(".esri-sketch").style.display = "block";
                                ;
                            }, 500);
                        }
                        props.updateMapLoad(true);
                    })
                    .catch();

                return () => {
                    document.getElementById("screenshot").removeEventListener("keyup", screenShootClick, true);
                    document.getElementById("destroy_graphics").removeEventListener("keyup", destroyGraphics, true);
                    document.getElementById("popup_address").removeEventListener("keyup", popupAddressClick, true);
                    // document.getElementById("popup").removeEventListener("keyup", popupClick, true);



                    if (view) {
                        view.container = null;
                    }
                };
            }

        );



        //if (props.fstate.problemLocation.value) {
        //    document.getElementById("popup").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' })); 
        //}
    }, [props.refresh]);


  


  return (
    <>
      {props &&
      props.fstate &&
      props.fstate.problemLocation &&
      props.fstate.problemLocation.disable ? (
        ""
      ) : (
        <FormGroup>
      

          <div id="mapInstructions" className="hideElement">
            <Label className="line"></Label>
            <div className="display-linebreak">
              {props.translate(props.lang, props.fstate.mapInstructions.label)}
            </div>
            <Label className="line"></Label>{" "}
          </div>
       

                      <ListGroup className="flex-select1">
                          {/*<PRInput*/}
                          {/*    elem={props.fstate.problemLocation}*/}
                          {/*    validate={(e) => {*/}
                          {/*        props.validate(e);*/}
                          {/*    }}*/}
                          {/*    translate={props.translate}*/}
                          {/*    placeholder={props.fstate.problemLocation.placeholder}*/}
                          {/*    lang={props.lang}*/}
                          {/*/>*/}
                          <Input
                              key={props.fstate.problemLocation.name}
                              type="input"
                              autoComplete="off"
                              name={props.fstate.problemLocation.name}
                              id={props.fstate.problemLocation.name}
                              placeholder={
                                  props.fstate.problemLocation.placeholder
                                      ? props.translate(
                                          props.lang,
                                          props.fstate.problemLocation.placeholder
                                      )
                                      : ""
                              }
                              value={props.fstate.problemLocation.valueDesc}
                         
                              valid={(document.getElementById("pinGeocoder") && document.getElementById("pinGeocoder").getAttribute("problemLocation")) ? true : false}
                              invalid={!(document.getElementById("pinGeocoder") && document.getElementById("pinGeocoder").getAttribute("problemLocation")) ? true : false}
                              maprefresh={props.maprefresh}
                              onChange={(e) => {
                                  e.stopPropagation();
                                  if ($(".listAddress.stack-top"))
                                      $(".listAddress.stack-top").css("display", "none");
          
                                  document.getElementById("destroy_graphics").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' })); 
                                  props.getAddreses(e);
                              }}
                          />
                          <div className="warningmsg">
                          {props.fstate.problemLocation.required && (!props.fstate.problemLocation.value || props.fstate.problemLocation.value === "")
                              ?
                     
                              (props.fstate.problemLocation.errorlabel && props.fstate.problemLocation.errorlabel.length > 0 ? props.translate(props.lang, props.fstate.problemLocation.errorlabel)
                                  : props.translate(props.lang, props.fstate.problemLocation.label).replace(':', '') + " " + props.translate(props.lang, "isRequired") + '. ')
                              : ""}
                          </div>
         
            {props.gisAddreses ? (
              <div className="listAddress stack-top">
                {props.gisAddreses.map((a, index) => (
                  <div
                    className="flex-select-list-item"
                    key={props.fstate.problemLocation.name + index + "div"}
                  >
                    <ListGroupItem
                      tag="button"
                      action
                      key={props.fstate.problemLocation.name + index + "el"}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.listOfAddressCandidates();
                        props.clearAlerts();
                        document
                          .getElementById("elementRunXYfromAddress")
                          .setAttribute("x", a.X.toString());
                        document
                          .getElementById("elementRunXYfromAddress")
                          .setAttribute("y", a.Y.toString());
                        document
                          .getElementById("elementRunXYfromAddress")
                          .setAttribute("sellectedAddress", a.Address);
                        document
                              .getElementById("elementRunXYfromAddress")
                              .dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' })); //.click();
                          /*.click();*/
                        e.preventDefault();
                            }}
                            onKeyUp={(e) => {
                                e.stopPropagation();
                                props.listOfAddressCandidates();
                                props.clearAlerts();
                                document
                                    .getElementById("elementRunXYfromAddress")
                                    .setAttribute("x", a.X.toString());
                                document
                                    .getElementById("elementRunXYfromAddress")
                                    .setAttribute("y", a.Y.toString());
                                document
                                    .getElementById("elementRunXYfromAddress")
                                    .setAttribute("sellectedAddress", a.Address);
                                document
                                    .getElementById("elementRunXYfromAddress")
                                    .dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' }));
                                    
                                e.preventDefault();
                            }}
                    >
                      <ListGroupItemText>{a.Address} </ListGroupItemText>
                    </ListGroupItem>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </ListGroup>

          <FormFeedback valid></FormFeedback>
          <FormFeedback>
            {props.fstate.problemLocation.errorlabel &&
            props.fstate.problemLocation.errorlabel.length > 0
              ? props.translate(
                  props.lang,
                  props.fstate.problemLocation.label
                ) +
                " " +
                props.translate(props.lang, "isRequired") +
                " " +
                (props.errmsg ? props.translate(props.lang, props.errmsg) : "")
              : ""}
          </FormFeedback>
        </FormGroup>
      )}

      {props.useMapMeasurement ? (
        <div id="toolbarDiv" className="esri-component esri-widget ">
          <button
            id="distance"
            className="esri-widget--button esri-interactive esri-icon-measure-line "
            title="Distance Measurement Tool"
          ></button>
          <button
            id="area"
            className="esri-widget--button esri-interactive esri-icon-measure-area "
            title="Area Measurement Tool"
          ></button>
          <button
            id="clear"
            className="esri-widget--button esri-interactive esri-icon-trash "
            title="Clear Measurements"
          ></button>
        </div>
      ) : (
        ""
      )}

      <div id="_map_list">
        <button id="destroy_graphics" className="hideElement"></button>
        <button id="popup" className="hideElement"></button>
        <button id="screenshot" className="hideElement"></button>
        <button id="popup_address" className="hideElement"></button>
        <div className="webmap" ref={mapRef} id="map">
                  {" "}
               
        </div>
        <div className="container">
          {props.FeatureServiceUrl ? <div id="tableDiv"></div> : ""}
        </div>

        <div
          id="btnGraphicsActions"
          className={
            props.useMapGraphics
              ? "btnsContainerRow"
              : "btnsContainerRow div_hidden"
          }
        >
        </div>
      </div>
    </>
  );
};
