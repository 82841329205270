import React, { useState, useEffect, useRef } from "react";
import {
    Form,
  Input,
  ListGroup,
  ListGroupItem,
  Label,
  FormGroup,
  ListGroupItemHeading,
    ListGroupItemText,
    Button
} from "reactstrap";

import PopoverItem from "../common/PRpopover";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function HomeSelect(props) {
  
  const node = useRef();
  const [showProbType, setShowProbType] = useState(false);
  const [showProbTypeList, setShowProbTypeList] = useState(false);
  const [pTypes, setPTypes] = useState([]);

  useEffect(() => {
    setPTypes(props.problemTypes);
    if (props.openProblemsList) onChange({ target: { value: " " } });
  }, []);

  const showHideForm = (e) => {
    setShowProbType(true);
  };

  const onChange = (e) => {
    setPTypes([]);
    if ($(".listAddress.stack-top"))
      $(".listAddress.stack-top").css("display", "none");
      if (document.getElementById("destroy_graphics"))document.getElementById("destroy_graphics").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' })); //.click();


    if (e && e.target && e.target.value) {
      props.fstate.problemtype.valueDesc = e.target.value;
      setShowProbType(false);

      setShowProbTypeList(true);

      let list = props.problemTypes.filter(
        (el) =>
          (el.description &&
            el.description
              .toUpperCase()
              .indexOf(e.target.value.toUpperCase().trim()) !== -1) ||
          (el.keywords &&
            el.keywords.toUpperCase().indexOf(e.target.value.toUpperCase().trim()) !==
              -1)
      );

        setPTypes(list);
    } else {
      props.onchange(null);

      props.fstate.problemtype.value = "";
      props.fstate.problemtype.valueDesc = "";
      setPTypes(props.problemTypes);
      setShowProbType(true);
      if (document.querySelector(".esri-sketch"))
        document.querySelector(".esri-sketch").style.display = "none";
      if (document.getElementById("mapInstructions"))
        document.getElementById("mapInstructions").style.display = "none";
    }
    };

    const handleKeyDown = (e) => {
        if (e) {
            e.target.style.height = 'inherit';
        }  
    }

    const onClear = (e) => {
        props.onchange(null);
        document.getElementById("problemtype").value="";
        
        props.fstate.problemtype.value = "";
        props.fstate.problemtype.valueDesc = "";
        setPTypes(props.problemTypes);
        setShowProbType(true);
        if (document.querySelector(".esri-sketch"))
            document.querySelector(".esri-sketch").style.display = "none";
        if (document.getElementById("mapInstructions"))
            document.getElementById("mapInstructions").style.display = "none";
        document.getElementById("problemtype").value = "";
       
            resizeProblems();
        if (props.openProblemsList) onChange({ target: { value: " " } });
    }


  const onClickListItem = (e, a) => {
    if ($(".listAddress.stack-top"))
      $(".listAddress.stack-top").css("display", "none");
 
      if (document.getElementById("destroy_graphics") )document.getElementById("destroy_graphics").dispatchEvent(new KeyboardEvent('keyup', { 'key': 'y' }));

    if (document.querySelector(".esri-sketch"))
      document.querySelector(".esri-sketch").style.display = "none";


    if (document.getElementById("problemSelectLabel")) {
      document
        .getElementById("problemSelectLabel")
        .setAttribute("drawingRequiredForTemplateKeywordFound", false);

     /* props.reRefresh();*/
     
    }

    props.fstate.problemtype.value = a.problemSid;
      props.fstate.problemtype.valueDesc = a.description;
      props.fstate.problemLocation.value = "";
      props.fstate.problemLocation.valueDesc = "";
      props.fstate.coordinatesx.value = "";
      props.fstate.coordinatesx.value = "";
    props.getQA(a.problemSid);
   
    setShowProbType(false);
      setShowProbTypeList(false);

        if (props.fstate.mapInstructions.disable === false)
            document.getElementById("mapInstructions").style.display = "block";
        else
            document.getElementById("mapInstructions").style.display = "none";
    //Add indicator for request SR template
      if (a && a.problemSid) {
          //props.showMap
          let prob = props.problemTypes.find(
              (f) => f.problemSid === a.problemSid
          );
          // console.log("HM props.showMap=>",props.showMap);
          // console.log("HM props.showMap2=>",prob.keywords.indexOf(props.hideMapByTemplateKeyword));
          if (props.showMap === true) {
              if (prob &&
                  prob.keywords &&
                  prob.keywords.indexOf(props.hideMapByTemplateKeyword) !== -1) {
                  if (document.getElementById("core_state")) {
                      document
                          .getElementById("core_state")
                          .setAttribute("showmapkeyword", false);
                  }
              } else {
                  if (document.getElementById("core_state")) {
                      document
                          .getElementById("core_state")
                          .setAttribute("showmapkeyword", true);
                  }
              }
          } else {
              if (document.getElementById("core_state")) {
                  document
                      .getElementById("core_state")
                      .setAttribute("showmapkeyword", false);
              }
          }
      

      if (props.drawingRequiredForAllTypes === false) {
        let prob = props.problemTypes.find(
          (f) => f.problemSid === a.problemSid
        );
        if (
          prob &&
          prob.keywords &&
          prob.keywords.indexOf(props.drawingRequiredForTemplateKeyword) != -1
        ) {
          document
            .getElementById("problemSelectLabel")
            .setAttribute("drawingRequiredForTemplateKeywordFound", true);
          if (document.querySelector(".esri-sketch"))
            document.querySelector(".esri-sketch").style.display = "block";
          //if (props.fstate.mapInstructions.disable === false)
          //  document.getElementById("mapInstructions").style.display = "block";
        } else {
          if (document.querySelector(".esri-sketch"))
            document.querySelector(".esri-sketch").style.display = "none";
          //if (document.getElementById("mapInstructions"))
          //  document.getElementById("mapInstructions").style.display = "none";
          if (document.getElementById("problemSelectLabel")) {
            document
              .getElementById("problemSelectLabel")
              .setAttribute("drawingRequiredForTemplateKeywordFound", false);
          }
        }
      } else {
        if (document.querySelector(".esri-sketch"))
          document.querySelector(".esri-sketch").style.display = "block";
        //if (props.fstate.mapInstructions.disable === false)
        //  document.getElementById("mapInstructions").style.display = "block";
      }
    } else {
      if (document.querySelector(".esri-sketch"))
        document.querySelector(".esri-sketch").style.display = "none";
      //if (document.getElementById("mapInstructions"))
      //  document.getElementById("mapInstructions").style.display = "none";
      }
      resizeProblems();
     
  };

    const resizeProblems = () => {
        var el = document.getElementById("problemtype");
        if (el) {
            el.style.height = 'inherit';
            el.style.height = `${el.scrollHeight - 20}px`;
        } 
    }

  return (
    <div ref={node}>
      {!(
        props.fstate &&
        props.fstate.problemtype &&
        !props.fstate.problemtype.disable &&
        props.problemTypes
      ) ? (
        ""
      ) : (
                  <FormGroup>
                   
          <Label
            for="problemSelect"
            id="problemSelectLabel"
            className={props.fstate.problemtype.fontWeight ? "label-font" : ""}
          >
            {props.translate(props.lang, props.fstate.problemtype.label)}{" "}
            {props.fstate.problemtype.required ? (
              <></>
            ) : (
              <span className="colorred">
                {props.translate(props.lang, "optionalSign")}
              </span>
                          )}
                         
          </Label>
          <PopoverItem
            elem={props.fstate.problemtype}
            translate={props.translate}
            lang={props.lang}
                      >
                         
                      </PopoverItem>
          <div
            id="problemtypeHint"
            dangerouslySetInnerHTML={{
              __html: props.translate(props.lang, "problemtypeHint"),
            }}
          ></div>
                     
          <ListGroup className="flex-select1">
                          <ListGroupItem >
                                
                              <Input className="textareaEdit  pbtype"
                                  type="textarea"
                                  autoComplete="off"
                                  placeholder={props.translate(
                                      props.lang,
                                      props.fstate.problemtype.placeholder
                                  )}
                                  key={props.fstate.problemtype.name}
                                  name={props.fstate.problemtype.name}
                                  id={props.fstate.problemtype.name}
                                  value={
                                      props.fstate.problemtype.valueDesc
                                          ? props.fstate.problemtype.valueDesc
                                          : ""
                                  }
                                  valid={props.fstate.problemtype.value != 0}
                                  invalid={props.fstate.problemtype.value === undefined || props.fstate.problemtype.value.length === 0}

                                  onFocus={(e) => {
                                      e.stopPropagation();
                                      showHideForm(e);
                                      onChange(e);
                                  }}
                                  onChange={(e) => {
                                      onChange(e);  
                                  }}
                                  onKeyDown={(e) => { handleKeyDown(e) }}
                                  
                              />
                              <FontAwesomeIcon
                                  icon={faXmark}
                                  className="problemClear"
                                  onClick={(e) => {
                                      
                                      onClear(e);
                                  }}
                              />
                             
            </ListGroupItem>

            {showProbTypeList ? (
              <div className="flex-select-list">
                {pTypes.map((a) => (
                  <div className="flex-select-list-item" key={a.problemSid}>
                    <ListGroupItem
                      tag="button"
                      action
                      key={a.problemSid}
                      onClick={(e) => {
                        onClickListItem(e, a);
                            }}
                            onKeyUp={(e) => {
                                onClickListItem(e, a);
                            }}
                    >
                      <ListGroupItemHeading>
                        {" "}
                        {a.description}
                      </ListGroupItemHeading>
                      <ListGroupItemText>{a.problemCode} </ListGroupItemText>
                    </ListGroupItem>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}

            {showProbType &&
            props.problemTypesCommon &&
            props.problemTypesCommon.length > 0 ? (
              <div>
                <ListGroupItem
                  disabled
                  tag="a"
                  href="#"
                  className="flex-select-list-item"
                >
                  <span className="label-font">
                    {props.translate(props.lang, "selectCommonRequestsLabel")}
                  </span>
                </ListGroupItem>

                <div className="flex-select">
                  <div className="flex-select-elem">
                    {props.problemTypesCommon.map((a, index) =>
                      index % 2 !== 0 ? (
                        ""
                      ) : (
                        <ListGroupItem
                          key={a.problemSid}
                          tag="button"
                          action
                          onClick={(e) => {
                            onClickListItem(e, a);
                                    }}
                                    onKeyUp={(e) => {
                                        onClickListItem(e, a);
                                    }}
                        >
                          {a.description}
                        </ListGroupItem>
                      
                      )
                    )}
                  </div>

                  <div className="flex-select-elem">
                    {props.problemTypesCommon.map((a, index) =>
                      index % 2 !== 0 ? (
                       
                        <ListGroupItem
                          key={a.problemSid}
                          tag="button"
                          action
                          onClick={(e) => {
                            onClickListItem(e, a);
                                }}
                                onKeyUp={(e) => {
                                    onClickListItem(e, a);
                                }}
                        >
                          {a.description}
                        </ListGroupItem>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </ListGroup>
        </FormGroup>
      )}
    </div>
  );
}
